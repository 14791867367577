import { useParams } from "react-router-dom";
import theme from "../../../application/utils/Theme";
import MainButton from "../buttons/MainButton";
import { FaPrint } from 'react-icons/fa';
import { SpinnerCircular } from 'spinners-react';
import usePrintTag from "../../../application/hooks/usePrintTag";
import ManifestSlipComponent from "./ManifestSlipComponent";
import { useGetManifestByReferenceNo } from "../../../application/hooks/queryHooks";

const ViewManifestSlip = () => {

    const params = useParams();
    const { data: manifestData, isLoading } = useGetManifestByReferenceNo(params.referenceNumber.toLowerCase());

    const [currentId, setCurrentId] = usePrintTag();
    
    return (
        <div className="flex flex-col items-center h-full w-full">
            {
                (manifestData !== undefined && manifestData.data !== null) &&
                <>
                    <ManifestSlipComponent id="printslip" details={manifestData.data} />

                    <div className="flex mt-4">
                        <MainButton onClick={() => setCurrentId("printslip")} background={theme.textColor} color={theme.color}>
                            <div className="flex items-center">
                                <FaPrint className="mr-2" />PRINT
                            </div>
                        </MainButton>
                    </div>
                </>
            }
            {
                isLoading &&
                <div className=" h-full w-full flex items-center justify-center">
                    <SpinnerCircular size={40} thickness={150} />
                </div>
            }
            {
                (manifestData !== undefined && manifestData.data === null) &&
                <div className="flex  h-full items-center justify-center">
                    <span className="text-[red] tracking-[0.1em] font-bold  text-[12px] md:text-[16px]">
                        {`MANIFEST WITH REFERENCE NO-${params.referenceNumber.toUpperCase()} NOT FOUND!`}
                    </span>
                </div>
            }

        </div>

    );
}

export default ViewManifestSlip;