import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ setIsModalActive }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const openAdminModal = () => {
    setIsModalActive(true);
    navigate("/login/admin");
  };

  const openTajnidModal = () => {
    setIsModalActive(true);
    navigate("/login/tajneed");
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white text-gray-700 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-2xl font-bold">Jalsa Salana Nigeria</h1>
        
        {/* Hamburger Icon for mobile */}
        <button 
          className="md:hidden block text-green-600" 
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>

        {/* Full Menu */}
        <div className={`md:flex hidden md:items-center gap-8`}>
          <a
            className="text-green-600 font-bold hover:underline cursor-pointer"
            onClick={() => openAdminModal()}
          >
            Admin
          </a>
          <a
            className="text-green-600 font-bold hover:underline cursor-pointer"
            onClick={() => openTajnidModal()}
          >
            Tajneed Team
          </a>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden flex flex-col gap-4 mt-4">
          <a
            className="text-green-600 font-bold hover:underline cursor-pointer"
            onClick={() => {
              openAdminModal();
              toggleMenu();
            }}
          >
            Admin
          </a>
          <a
            className="text-green-600 font-bold hover:underline cursor-pointer"
            onClick={() => {
              openTajnidModal();
              toggleMenu();
            }}
          >
            Tajneed Team
          </a>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
