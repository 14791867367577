import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../store/actions/ui";

const useAuthorization = () => {

    const dispatch = useDispatch();

    const userAuthLevel = useSelector(state => state.user.userAuthLevel);
    const isAuthorized = userAuthLevel == "national" || userAuthLevel == "circuit" || userAuthLevel == "jamaat"|| userAuthLevel == "admin";

    useEffect(() => {
        if (!isAuthorized) {
            dispatch(setLoading(false))
            window.history.back();
            return;
        }
    }, [])
    return null;
};

export default useAuthorization;