import React, { useEffect, useMemo, useState } from "react";
import { useTable, useGlobalFilter, usePagination, useRowSelect } from "react-table/dist/react-table.development";
import MainButton from "../buttons/MainButton";
import SmallButton from "../buttons/SmallButton";
import { COLUMNS } from "../../../application/utils/columns/MemberColumn";
import { CheckBox } from "../table/CheckBox";
import '../table/Table.css';
import MemberFilter from "../table/GlobalFilter";
import PaginateComponent from "../table/PaginateComponent";
import { useSelector } from "react-redux";
import services from "../../../ioc/services";
import { setLoading } from '../../../application/store/actions/ui';
import { SpinnerCircular, SpinnerDotted } from 'spinners-react';
import useAuthorization from "../../../application/hooks/useAuthorization";
import { useLocation } from "react-router-dom";

const RegisterMember = () => {
   useAuthorization();
    const location = useLocation();

    const [isRegistering, setIsRegistering] = useState(false);
    const circuitId = useSelector(state => state.user.circuitId);
    const jamaatId = useSelector(state => state.user.jamaatId);
    const userAuthLevel = useSelector(state => state.user.userAuthLevel);
    const activeOption =  location.pathname.split("/")[2];
    
    const columns = useMemo(() => COLUMNS, []);
    const [circuitData, setCircuitData] = useState([]);

    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
        setGlobalFilter,
        selectedFlatRows,
    } = useTable({
        columns,
        data: circuitData
    },
        useGlobalFilter,
        usePagination,
        useRowSelect,
        (hooks) => {

            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <CheckBox className="toggleCheck"  {...getToggleAllRowsSelectedProps()} />
                    ),
                    Cell: ({ row }) => <CheckBox className="toggleCheck"  {...row.getToggleRowSelectedProps()} />,
                },
                ...columns,
            ]);
        })

    const { globalFilter, pageIndex, pageSize } = state;
    
    const [activePageOption, setActivePageOption] = useState({ length: 1 });
    const [activePageIndex, setActivePageIndex] = useState(0);
    const [prevActivePage, setPrevActivePage] = useState(activePageIndex !== 0);
    const [nextActivePage, setNextActivePage] = useState(activePageIndex + 1 < activePageOption.length);
    const [isLoading, setIsLoading] = useState(false);
    const [registering, setRegistering] = useState(1);

    const goToNextPage = () => {
        setActivePageIndex(activePageIndex + 1);
    }


    const goToPreviousPage = () => {
        setActivePageIndex(activePageIndex - 1);
    }
   
    useEffect(()=>{
        setIsLoading(true);      
        if(activeOption === "jamaat"){
            services.api.userRequests.getUnRegisteredMembersByJamaatId(jamaatId, activePageIndex + 1, pageSize).then(res => {
                setActivePageOption({ length: Math.round(res.data.totalCount / pageSize) !== 0 ? Math.round(res.data.totalCount / pageSize) : 1 });
                setCircuitData(res.data.rows);
                setIsLoading(false);
            })
        }
        else if(activeOption === "circuit")
        {
            services.api.userRequests.getUnRegisteredMembersByCircuitId(circuitId, activePageIndex + 1, pageSize).then(res => {
                setActivePageOption({ length: Math.round(res.data.totalCount / pageSize) !== 0 ? Math.round(res.data.totalCount / pageSize) : 1 });
                setCircuitData(res.data.rows);
                setIsLoading(false);
            })
        }
       
    }, [circuitId, jamaatId, activePageIndex, pageSize, registering])

    useEffect(() => {
        setPrevActivePage(activePageIndex !== 0);
        setNextActivePage(activePageIndex + 1 < activePageOption.length);
    }, [activePageIndex, activePageOption]);

  

    const registerMember = (memberNo) => {
        setIsRegistering(true);
        services.api.userRequests.registerMember(memberNo).then(res => {
            if (!res.data.succeeded) {
                services.toast.error(res.data?.messages[0]);
            }
            else {
                services.toast.success(res.data?.messages[0]);
                setRegistering(Math.random());
            }
            setIsRegistering(false);
        })
    }

    const registerMembersInBulk = () => {
        setIsRegistering(true);
        const memberNumbers = selectedFlatRows.map((row) => row.original.chandaNo);
        services.api.userRequests.registerBulkMembers(memberNumbers).then(res => {
            
            if (res.data.length == 0) {
                services.toast.error(res.messages[0]);
            }
            else {
                services.toast.success(res.messages[0]);
                setRegistering(Math.random());
            }
            setIsRegistering(false);
        }
        )
    }

    return (
        <div className="w-full h-[80%] md:h-full  items-center md:w-[95%] ">
            <div className="items-center flex">

                <div className="flex-1">
                    {
                        selectedFlatRows.length > 1 &&
                        <MainButton className="next-prev flex items-center" color="white" background="#16A34A" onClick={() => { registerMembersInBulk() }}><span>REGISTER BULK ({selectedFlatRows.length})</span>
                        </MainButton> 
                    }
                    
                </div>

                <div className="flex justify-end">
                    <SpinnerCircular color="white" secondaryColor="#16A34A" size={20} thickness={150} enabled={isRegistering} />
                    <MemberFilter filter={globalFilter} setFilter={setGlobalFilter} />
                </div>
            </div>

            {
                (!isLoading && circuitData.length > 0) &&
                <div className="md:px-5 h-[95%]  overflow-y-auto">
                    <table  {...getTableProps()} className="table">
                        <thead className="thead">
                            {
                                headerGroups.map((headerGroup, index) => (
                                    <tr key={index} {...headerGroup.getHeaderGroupProps()} className="tr-head">

                                        {

                                            headerGroup.headers.map((column, index) => (
                                                <th key={index * 0.5} {...column.getHeaderProps()}>{column.render('Header')}</th>
                                            ))
                                        }
                                        <th>NAME</th>
                                        <th>ACTION</th>
                                    </tr>
                                ))
                            }

                        </thead>
                        <tbody {...getTableBodyProps()} className="tbody">
                            {
                                page.map((row, index) => {
                                    prepareRow(row)
                                    return (
                                        <React.Fragment key={index}>
                                            <tr  {...row.getRowProps()} className="tr-body">
                                                {
                                                    row.cells.map((cell, index) => {
                                                        return <td key={index} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                    })
                                                }

                                                <td>{`${row.original.surname} ${row.original.firstName}`}</td>
                                                <td>
                                                    <SmallButton className="tracking-[0.1rem]" onClick={() => { registerMember(row.original.chandaNo) }}><span>REGISTER</span></SmallButton>

                                                </td>
                                            </tr>
                                            <tr className="border-t-[2px] w-[50%] opacity-[49%]">
                                                <td></td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }
            {
                isLoading &&
                <div className=" h-full w-full flex items-center justify-center">
                    <SpinnerDotted size={40} thickness={150} />
                </div>
            }

            <PaginateComponent pageIndex={activePageIndex} pageOptions={activePageOption} canNextPage={nextActivePage} canPreviousPage={prevActivePage} previousPage={goToPreviousPage} nextPage={goToNextPage} pageSize={pageSize} setPageSize={setPageSize} />

        </div >
    );
}

export default RegisterMember;