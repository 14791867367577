export const COLUMNS = [

    {
        Header: "DEPARTMENT NAME",
        accessor: "name",
    
    },
    {
        Header: "DESCRIPTION",
        accessor: "description",
        Cell: ({ value }) => {
            return value ?? "-";
        }
    
    },
   
]