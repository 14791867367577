import { useLocation, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import MainButton from "../buttons/MainButton";
import FormikControl from "../formik/FormikControl";
import { FaEdit, FaPlus } from "react-icons/fa";
import { useEffect, useState } from "react";
import services from "../../../ioc/services";
import { useNavigate } from "react-router-dom";
import { SpinnerCircular } from 'spinners-react';
import DisabledButton from "../buttons/DisabledButton";

const AddEditDepartment = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const activeOption = location.pathname.split("/")[2];
    const isEdit = activeOption == 'edit'

   
    const [initialValues, setInitialValues] = useState({
        departmentName: '',
        description: '',
    });

    const validationSchema = Yup.object({
        departmentName: Yup.string().required("Department name is required")
    });

    const displayInput = [
        {
            label: "name",
            name: "departmentName",
            control: "input",
            placeholder: "Enter department name",
        },
        {
            label: "description",
            name: "description",
            control: "input",
            placeholder: "Enter department description",
        }
    ]

    const [isAdding, setIsAdding] = useState(false);

    const submit = (values) => {
        const departmentDetail = {
            name: values.departmentName,
            description: values.description,
        }

        if (!isEdit) {
            setIsAdding(true);
            services.api.userRequests.addDepartment(departmentDetail).then(response => {
                if (response?.data?.status) {
                    services.toast.success(response?.data?.message);
                    navigate("/departments");
                }
                else services.toast.error(response?.data?.message);
                setIsAdding(false);
            })
        }
        else if(isEdit)
        {
            setIsAdding(true);
            departmentDetail.id = params.departmentId;
            services.api.userRequests.editDepartment(departmentDetail).then(response=> {
                if(response?.succeeded)
                {
                    services.toast.success(response?.messages[0]);
                    navigate("/departments");
                }  
                else{
                    services.toast.error(response?.message);
                }
                setIsAdding(false);
            })
        }
    }

    useEffect(() => {
        if (isEdit) {
            services.api.userRequests.getDepartmentById(params.departmentId).then(res => {
                const department = {
                    departmentName: res?.data?.name,
                    description: res?.data?.description,
                }
                setInitialValues(department);
            });
        }
    }, [])

    return (
        <div>
            <h2 className="flex justify-center items-center my-6 font-bold text-[20px]">{isEdit ? <FaEdit className="mx-2" /> : <FaPlus className="mx-2" />} DEPARTMENT</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                enableReinitialize
                onSubmit={submit}
            >
                {formik => (

                    <Form>
                        <div className="register-component grid-column" >
                            {displayInput.map((d, index) => (
                                <FormikControl
                                    key={index * 0.5}
                                    label={d.label}
                                    name={d.name}
                                    placeholder={d.placeholder}
                                    options={d?.options}
                                    control={d.control}
                                    type={d?.type}
                                />
                            ))}

                        </div>
                        <div className="register-btn">
                            {
                                !isAdding &&
                                <MainButton className="flex items-center" type="submit" > <span>{isEdit ? "EDIT" : "ADD "} DEPARTMENT</span> </MainButton>
                            }
                            {
                                isAdding &&
                                <DisabledButton disabled={true} className="flex items-center"> <span>{isEdit ? "EDIT" : "ADD "} DEPARTMENT</span> <SpinnerCircular className="ml-3" color="white" secondaryColor="#EEF0F2" size={20} thickness={150} enabled={isAdding} /> </DisabledButton>
                            }
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default AddEditDepartment;

