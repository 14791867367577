import { useState } from "react";
import { SpinnerCircular } from "spinners-react";
import services from "../../../ioc/services";
import DisabledButton from "../buttons/DisabledButton";
import MainButton from "../buttons/MainButton";
import InputLayout from "../inputs/InputLayout";
import { InputField } from "../inputs/MainInput";

const AddRole = () => {

    const [input, setInput] = useState('');

    const [isAdding, setIsAdding] = useState(false);
    const addRole = () => {
        const roleDetail = {
            name: input
        }
        if(input.trim()== ''){
            services.toast.error("Enter Role Name");
            return;
        }
        setIsAdding(true);
        services.api.userRequests.addRole(roleDetail).then(res => {
            if (res?.data?.status) {
                services.toast.success(res.data.message)
            }
            else if(!res?.status) {
                services.toast.error(res.message)
            }
            setIsAdding(false);
        })
    }
    return (
        <>
            <div className="my-10">
                <h1 className="font-bold text-[20px] text-center mb-7">ADD ROLE</h1>

            </div>
            <div className="">
                <InputLayout label="role">
                    <InputField placeholder="Enter Role Name" value={input} onChange={(e) => setInput(e.target.value)} />
                </InputLayout>
                <div className="my-4"></div>
                {
                    !isAdding &&
                    <MainButton onClick={addRole}>ADD ROLE</MainButton>
                }
                {
                    isAdding &&
                    <DisabledButton disabled={true} className="flex" > <span>ADD ROLE</span> <SpinnerCircular className="ml-3" color="white" secondaryColor="#EEF0F2" size={20} thickness={150} enabled={isAdding} /> </DisabledButton>
                }

            </div>
        </>
    )
}

export default AddRole;
