import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetManifestByReferenceNo } from "../../../application/hooks/queryHooks";
import { DisplayDetails, DisplayText } from "../display/DisplayDetails";
import { InputLabel } from "../inputs/MainInput";
import "../register/RegisterComponent.css";
import './ManifestComponent.css';
import { SpinnerCircular } from 'spinners-react';


const DisplayManifestDetails = () => {

    const params = useParams();
    const { data, isLoading } = useGetManifestByReferenceNo(params.referenceNumber.toLowerCase());

    const [manifestDetails, setManifestDetails] = useState([]);

    useEffect(() => {
        if (data !== undefined && data.data !== null) {
           
            setManifestDetails({
                plateNumber: data.data.vehicleNumber.toUpperCase(),
                manifestDescription: data.data.description?.toUpperCase(),
                totalManifestMembers: data.data.manifestOccupants.length,
                vehicleType: data.data.vehicleTypeName.toUpperCase(),
                jalsaYear: 2022,
                capacity: data.data.capacity,
                dateCreated: new Date(data.data.createdOn).toLocaleDateString(),
                lastUpdated: new Date(data.data.lastModified).toLocaleDateString(),
            })
        }
    }, [isLoading])
    
    const displayDetails = [
        {
            label: "LOCATION",
            text: manifestDetails.manifestDescription,
        },
        {
            label: "PLATE NUMBER",
            text: manifestDetails.plateNumber,
        },
        {
            label: "no. of members",
            text: manifestDetails.totalManifestMembers,
        },
        {
            label: "vehicle capacity",
            text: manifestDetails.capacity,
        },
        {
            label: "vehicle type",
            text: manifestDetails.vehicleType,
        },
        {
            label: "jalsa year",
            text: manifestDetails.jalsaYear,
        },
        {
            label: "date created",
            text: manifestDetails.dateCreated
        },
        {
            label: "last updated",
            text: manifestDetails.lastUpdated
        },

    ]


    return (
        <>
            {
                (data !== undefined && data.data !== null) &&
                <>
                    <div className="flex w-full justify-center text-sm md:text-xl font-bold tracking-[0.2rem]">
                        <span>{`${"REFERENCE-NUMBER"}(${params.referenceNumber.toLowerCase()})`}</span>
                    </div>
                    <div className=" grid gap-6 pt-4 md:justify-center md:pt-16 md:gap-x-[25rem] md:gap-y-20 grid-column" >
                        {displayDetails.map(d => (
                            <DisplayDetails >
                                <InputLabel>{d.label.toUpperCase()}</InputLabel>
                                <DisplayText>{d.text}</DisplayText>
                            </DisplayDetails>
                        ))}
                    </div>
                </>
            }
            {
                isLoading &&
                <div className=" h-full w-full flex items-center justify-center">
                    <SpinnerCircular size={40} thickness={150} />
                </div>
            }
            {
                (data !== undefined && data.data === null) &&
                <div className="flex  h-full items-center justify-center">
                    <span className="text-[red] tracking-[0.1em] font-bold  text-[12px] md:text-[16px]">
                        {`MANIFEST WITH REFERENCE NO-${params.referenceNumber.toUpperCase()} NOT FOUND!`}
                    </span>
                </div>
            }

        </>
    );
}

export default DisplayManifestDetails;