
import { useNavigate, useParams } from "react-router-dom";
import services from "../../../ioc/services";

const CancelRegistrationModal = ({setRefresh}) => {

    const navigate = useNavigate();
    const params = useParams();

    const minimizeModal = (e) => {
        if(e.target.classList.contains("modal"))
        {
            navigate("/register");
        }
    }

    const calcelRegistration = () => {
        services.api.userRequests.cancelRegistration(params.jalsaNo).then(res=>{
            if(res?.data?.succeeded)
            {
                services.toast.success(res?.data?.messages[0]);
                setRefresh(Math.random(100));
            }
            else{
                services.toast.error(res?.data?.messages[0]);
            }
            navigate("/register");
        })
    }

    return (

        <div className="container flex justify-center mx-auto">
            <div className="absolute modalContainer inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 modal" onClick={(e)=>minimizeModal(e)}>
                <div className="max-w-sm p-6 bg-white divide-gray-500 ">
                    <div className="flex items-center justify-between">
                        <p>Are you sure you want to cancel registration for 
                            <strong> JALSA SALANA {new Date().getFullYear().toString()}</strong> 
                        </p>
                    </div>

                    <div className="text-center md:text-right mt-4 md:flex md:justify-end">
                        <button onClick={() => calcelRegistration()} className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-red-200 text-red-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2">Yes
                        </button>
                        <button onClick={(e) => minimizeModal(e)} className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4
        md:mt-0 md:order-1 modal">No</button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default CancelRegistrationModal;