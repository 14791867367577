import { useQuery, useMutation } from 'react-query';
import services from '../../ioc/services';

export const useGetMemberById = (memberNo) => {

    return useQuery(['userDetails', memberNo], () => services.api.userRequests.getMemberByMemberNo(memberNo));

}

const registerMember = (memberNo) => {
    return services.api.userRequests.registerMember(memberNo);
}

export const useRegisterMember = () => {
    return useMutation(registerMember);
}
export const useGetAllVolunteersWithoutPagination = () =>{
    return useQuery('volunteersWithNoPagination',() => services.api.userRequests.getAllVolunteersWithoutPagination());
}

export const useGetMemberManifestByMemberNo = (memberNo) => {
    return useQuery(['memberManifest', memberNo], () => services.api.userRequests.getMemberManifestByMemberNo(memberNo));
}

export const useGetParticipantTypes = () => {

    return useQuery('userDetails', () => services.api.userRequests.getParticipantTypes());

}
export const useGetVehicleTypes = () => {

    return useQuery('userDetails', () => services.api.userRequests.getVehicleTypes());

}

export const useGetParticipantByRegNo = (regNo) => {

    return useQuery(['participantDetails', regNo], () => services.api.userRequests.getParticipantByRegNo(regNo));

} 


export const useGetParticipantByMemberNo = (memberNo, refresh = 0) => {

    return useQuery(['userInfo', memberNo, refresh], () => services.api.userRequests.getParticipantByMemberNo(memberNo));

} 

export const useGetManifestByReferenceNo = (referenceNo) => {
          

    return useQuery(['manifestInfo', referenceNo], () => services.api.userRequests.getManifestByReferenceNo(referenceNo));

} 

export const useGetGuestParticipantsByCreatorNo = (creatorNo, pageNumber, pageSize) => {
    return useQuery(['guestParticipants', creatorNo, pageNumber, pageSize], () => services.api.userRequests.getGuestParticipantsByCreatorNo(creatorNo, pageNumber, pageSize));	
}

export const useGetMemberParticipantsByCreatorNo = (creatorNo, pageNumber, pageSize) => {
    return useQuery(['memberParticipants', creatorNo, pageNumber, pageSize], () => services.api.userRequests.getMemberParticipantsByCreatorNo(creatorNo, pageNumber, pageSize));	
}

export const useGetParticpantsByCreatorNo = (creatorNo) => {
    return useQuery(['participants', creatorNo], () => services.api.userRequests.getParticipantsByCreatorNo(creatorNo));	
}

export const useGetUnRegisteredMembersByCircuitId = (circuitId, pageNumber, pageSize) => {
    return useQuery(['UnregistedMembers', circuitId, pageNumber, pageSize], () => services.api.userRequests.getUnRegisteredMembersByCircuitId(circuitId, pageNumber, pageSize));
}

export const useGetPresentEvent = () => {
    return useQuery('event', () => services.api.userRequests.getPresentEvent());
}

export const useGetParticipantTag = (jalsaNo) => {
    return useQuery(['tag', jalsaNo], () => services.api.userRequests.getGetParticipantTag(jalsaNo));
}

export const useGetAllCircuits = () => {

    return useQuery('circuits', () => services.api.userRequests.getAllCircuits());

}

export const useGetAllJamaats = () => {

    return useQuery('jamaats', () => services.api.userRequests.getAllJamaats());

}

export const useGetNationalRegistrationStats = (eventYear) => {
    return useQuery(['nationalStats', eventYear], () => services.api.userRequests.getNationalRegistrationStats(eventYear));


}

export const useGetCircuitRegistrationStats = (circuitId, eventYear) => {

    return useQuery(['circuitStats', circuitId, eventYear], () => services.api.userRequests.getCircuitRegistrationStats(circuitId, eventYear));

}

export const useGetJamaatRegistrationStats = (jamaatId, eventYear) => {

    return useQuery(['jamaatStats', jamaatId, eventYear], () => services.api.userRequests.getJamaatRegistrationStats(jamaatId, eventYear));

}

export const useGetAllEvents = () => {

    return useQuery('events', () => services.api.userRequests.getAllEvents());

}

export const useGetAllUsers = () => {

    return useQuery('users', () => services.api.userRequests.getAllUsers());

}

export const useGetAllRoles = () => {

    return useQuery('roles', () => services.api.userRequests.getAllRoles());

}

export const useGetAllDepartments = () => {

    return useQuery('departments', () => services.api.userRequests.getAllDepartments());
}
export const useGetCircuitStats = () => {

    return useQuery('stats', () => services.api.userRequests.getAllCircuitsStats());
}

export const useGetAllVolunteers = (pageNumber, pageSize) => {

    return useQuery(['volunteers', pageNumber, pageSize], () => services.api.userRequests.getAllVolunteers(pageNumber, pageSize));
}