import SearchInput from "../inputs/SearchInput";


const MemberFilter = ({filter, setFilter}) => {


    return ( 
        <span>
            {/* <input type="search" value={filter} onChange={(e)=> setFilter(e.target.value)} /> */}
            <SearchInput filter={filter} setFilter={setFilter} placeHolder="Search"/>
        </span>
     );
}
 
export default MemberFilter;