import theme from "../../../application/utils/Theme";
import MainButton from "../buttons/MainButton";
import { FaPrint, FaDownload } from 'react-icons/fa';
import { TagComponent } from "./TagComponent";
import usePrintTag from "../../../application/hooks/usePrintTag";
import { useSelector } from "react-redux";
import { useGetParticipantByMemberNo } from "../../../application/hooks/queryHooks";
import { useEffect, useState } from "react";
import { SpinnerCircular } from 'spinners-react';

const ViewSelfTag = () => {

    const userId = useSelector(state => state.user.userId);

    const [currentId, setCurrentId] = usePrintTag();
    const [userDetails, setUserDetails] = useState({});

    const { data, isLoading } = useGetParticipantByMemberNo(userId);
    useEffect(() => {
        if (data !== undefined) setUserDetails(data);

    }, [isLoading])


    return (
        <div className="flex flex-col items-center h-full w-full">
            {
                userDetails.lastName !== undefined &&
                <>
                    <TagComponent id="print" details={userDetails} />
                    <div className="flex mt-4">
                        <MainButton onClick={() => setCurrentId("print")} background={theme.textColor} color={theme.color}>
                            <div className="flex items-center">
                                <FaPrint className="mr-2" />PRINT
                            </div>
                        </MainButton>
                    </div>
                </>
            }
            {
                isLoading &&
                <div className=" h-full w-full flex items-center justify-center">
                    <SpinnerCircular size={40} thickness={150} />
                </div>
            }
            {
                (userDetails.lastName == undefined && !isLoading) &&
                <div className="flex flex-col font-bold  h-full items-center justify-center">
                    <p className="tracking-[0.1em] text-[12px] md:text-[16px]">
                        REGISTER FOR JALSA YEAR {new Date().getFullYear()} BEFORE PRINTING TAG.
                    </p>
                    <p>
                        <a href="#/register" className="text-theme-color underline font-thin cursor-pointer">CLICK HERE</a> TO PROCEED WITH REGISTRATION.
                    </p>
                </div>
            }

        </div>
    );
}

export default ViewSelfTag;