export const COLUMNS = [
   
    {
        Header: "JALSA REG. NO",
        accessor: "registrationNumber",
    },
    {
        Header: "INVITED BY",
        accessor: "createdBy",
    },
    {
        Header: "PHONE NUMBER",
        accessor: "phoneNumber",
    },

]