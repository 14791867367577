
import './App.css';
import { ToastContainer } from 'react-toastify';
import HomePage from "./pages/Router";
import { QueryClientProvider, QueryClient } from 'react-query';

const App = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient} >
      <div className="App">
        <div className={`hidden bg-[red]`}  ></div>
        <ToastContainer />
        <HomePage />
      </div>
    </QueryClientProvider>

  );
}

export default App;
