import react from 'react'

export const CheckBox = react.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = react.useRef();
    const resolvedRef = ref || defaultRef;

    react.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate]);

    return (
        <input type='checkbox' ref={resolvedRef}  {...rest} />
    );
})
