
import React, { useMemo, useEffect, useState } from "react";
import { useTable, useGlobalFilter, usePagination, useRowSelect } from "react-table/dist/react-table.development";
import { COLUMNS } from "../../../application/utils/columns/CircuitStatsColumn";
import '../table/Table.css';
import { FaEdit, FaPlus, FaTrashAlt } from 'react-icons/fa';
import SmallButton from "../buttons/SmallButton";
import MainButton from "../buttons/MainButton";
import { useNavigate } from "react-router-dom";
import PaginateComponent from "../table/PaginateComponent";
import { SpinnerDotted } from 'spinners-react';
import { useGetCircuitStats } from "../../../application/hooks/queryHooks";
import DeleteDepartmentModal from "../modals/DeleteDepartmentModal";
import { useLocation } from "react-router";

const ListCircuitStats
    = () => {
        const navigate = useNavigate();
        const location = useLocation();
        const activeOption = location.pathname.split("/")[2] ?? "all";
        const columns = useMemo(() => COLUMNS, []);
        const [data, setData] = useState([]);
        const { data: departmentData, isLoading } = useGetCircuitStats();

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            prepareRow,
            state,
            nextPage,
            previousPage,
            canNextPage,
            canPreviousPage,
            pageOptions,
            setPageSize,
        } = useTable({
            columns,
            data,
        },
            useGlobalFilter,
            usePagination,
            useRowSelect,
        )

        const { pageIndex, pageSize } = state;


        useEffect(() => {
            if (departmentData !== undefined) {
                setData(departmentData);
                console.log(departmentData, "departmentData");
                console.log(headerGroups, "headerGroups");
            }
        }, [isLoading, departmentData])

        const [deptName, setDeptName] = useState("");

        return (
            <div className="w-full items-center h-[85%] md:h-[95%] ">
                <span className="opacity-[49%] tracking-[0.2em] font-bold flex justify-center md:my-[20px] text-[16px] md:text-[20px]">Stats</span>
                <div className="md:px-5 h-[70%] overflow-auto md:overflow-x-hidden">
                    {
                        data?.length > 0 &&
                        <table  {...getTableProps()} className="table md:ml-[20px]">
                            <thead className="thead">
                                {
                                    headerGroups.map((headerGroup, index) => (
                                        <tr key={index} {...headerGroup.getHeaderGroupProps()} className="tr-head">
                                            {
                                                headerGroup.headers.map((column, index) => (
                                                    <th key={index * 0.5} {...column.getHeaderProps()}>{column.render('Header')}</th>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </thead>
                            <tbody {...getTableBodyProps()} className="tbody">
                                {
                                    page.map((row, index) => {
                                        prepareRow(row)
                                        return (
                                            <React.Fragment key={index} >
                                                <tr {...row.getRowProps()} className="tr-body ">
                                                    {
                                                        row.cells.map((cell, index) => {
                                                            return <td key={index} {...cell.getCellProps()} >{cell.render('Cell')}</td>
                                                        })
                                                    }
                                                </tr>
                                            </React.Fragment>

                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    }
                    {
                        data?.length === 0 && !isLoading &&
                        <div className="flex  h-full items-center justify-center">
                            <span className="opacity-[49%] tracking-[0.1em] font-bold  text-[12px] md:text-[14px]">NO DEPARTMENT</span>
                        </div>
                    }
                    {
                        isLoading &&
                        <div className=" h-full w-full flex items-center justify-center">
                            <SpinnerDotted size={40} thickness={150} />
                        </div>
                    }

                </div>
                <PaginateComponent pageIndex={pageIndex} pageOptions={pageOptions} canNextPage={canNextPage} canPreviousPage={canPreviousPage} previousPage={previousPage} nextPage={nextPage} pageSize={pageSize} setPageSize={setPageSize} />
            </div >
        );
    }

export default ListCircuitStats
    ;
