import { useLocation } from "react-router-dom";
import useSelectAuthorization from "../../application/hooks/useSelectAuthorization";
import useAuthentication from "../../application/hooks/useAuthentication";
import ListDepartments from "../components/department/ListDepartments";
import AddEditDepartment from "../components/department/AddEditDepartment";
import NavigationLayout from "../layout/NavigationLayout";

const DepartmentContainer = () => {
    useAuthentication();
    useSelectAuthorization(['admin', 'nazim volunteer']);
    
    const location = useLocation();
    const activeOption = location.pathname.split("/")[2] ?? "all" ;
    return ( 
        <NavigationLayout>
        {
            (activeOption == 'add' || activeOption == 'edit') && <AddEditDepartment/>
            
        }
        {
            (activeOption == "all" || activeOption == 'delete') && <ListDepartments/>
        }
        </NavigationLayout>
     );
}
 
export default DepartmentContainer;