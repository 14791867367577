import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const StatsCard = ({ title, value, description, fraction, bgColor, withProgress = true, display = true, onClick }) => {
    const percentage = Math.round(fraction * 100)
   
    return (
        
        display &&
        <div className={`rounded-lg shadow-md p-4  flex-1 flex flex-col gap-4 items-center bg-[${bgColor}] hover:shadow-none cursor-pointer transition-all duration-500 `}
            style={{ backgroundColor: bgColor }} onClick={onClick} >

            <h5 className=" font-semibold text-md">{title}</h5>

            <div className='flex items-center justify-around w-full'>
                <h3 className=" font-bold text-3xl">{value?.toLocaleString("en-US")}</h3>
                
                {withProgress &&
                    <div className=' max-w-[60px]'>
                 
                        <CircularProgressbar value={percentage} strokeWidth={4} text={`${isNaN(percentage)? 0 : percentage}%`}

                            styles={buildStyles({
                                textColor: '#000',
                                pathColor: '#000',
                                pathTransitionDuration: 0.5,
                            })}
                        />
                        
                    </div>
                  
                }
            </div>
            <p className=" text-xs text-center">{description}</p>
            
            
        </div>
        
    )

}

export default StatsCard;