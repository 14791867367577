export const COLUMNS = [

    {
        Header: "DESCRIPTION",
        accessor: "description",
        Cell:({value})=>{
            return value?.toUpperCase();
        }
      
    },
    {
        Header: "PLATE NUMBER",
        accessor: "vehicleNumber",
        Cell: ({ value }) => {
            return value?.toUpperCase();
        }
    },
    {
        Header: "VEHICLE TYPE",
        accessor: "vehicleTypeName",
        // Cell: ({ value }) => {
        //     return value?.toUpperCase();
        // }
    },

    {
        Header: "TOTAL",
        accessor: "manifestOccupants",
        Cell: ({ value }) => {
            return value?.length;
        }
    }
   
]