import styled from "styled-components";
import theme from "../../../application/utils/Theme";


const MainButton = styled.button`
        background-color:  ${props => props.background ? props.background : theme.color};
        color: ${props => props.color ? props.color : theme.textColor};
        padding: 0.5em;
        font-weight: bolder;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        font-size: 0.8em;
        border-radius: 0.1rem;
        cursor: pointer;
        letter-spacing: 0.1em;
        @media (min-width: ${theme.breakPoint['tablet']}) {
            letter-spacing: 0.2em;
            font-size:1em;
        }

        &:hover{
            box-shadow: none;
        }
    `

export default MainButton;