import { FaUserPlus } from 'react-icons/fa';
import { TextError } from '../inputs/MainInput';
import { SpinnerCircular } from 'spinners-react';


const AddMembersModal = ({ setIsModalActive, userInput, setUserInput, addMemberToManifest, errorMessage, setErrorMessage, isLoading }) => {

    const minimizeModal = (e) => {
        if (e.target.classList.contains("modal")) {
            setUserInput("");
            setErrorMessage("");
            setIsModalActive(false);
        }
    }

    const submitMemberId = () => {
        if (userInput.length > 0) {
            addMemberToManifest();
            setErrorMessage("");
        }
    }


    return (
        <div className="fixed flex justify-center items-center modal bg-[#00000048] h-[100vh] w-[100vw] top-0 left-0 z-20" onClick={(e) => minimizeModal(e)}>
            <div className="bg-white h-[150px] md:h-[200px] w-[300px] md:w-[400px] shadow-lg flex flex-col p-4">
                <div className="flex items-center justify-center h-[20%]">
                    <h4 className="font-[600] tracking-[0.1em] text-[12px] md:text-[16px]">ADD PARTICIPANT TO MANIFEST</h4>
                </div>
                <div className="flex items-center h-[60%] ">
                    <input type="text" placeholder="Enter Participant Reg No." className={`border-2 border-theme-color p-2 w-full h-[40px] outline-none`} value={userInput} onChange={(e) => setUserInput(e.target.value)} />

                </div>
                {
                    errorMessage.length > 0 &&
                    <div className='md:mt-[-25px]'>
                        <TextError>{errorMessage}</TextError>
                    </div>
                }
                <div className="h[20%] flex items-center justify-center w-full">
                    <button className={`flex items-center font-[500] bg-theme-color text-white p-2`} onClick={() => { submitMemberId() }}>
                        <FaUserPlus className='mr-[5px]' />
                        Participant
                        <SpinnerCircular className="ml-3" color="white" secondaryColor="#EEF0F2" size={20} thickness={150} enabled={isLoading} />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AddMembersModal;