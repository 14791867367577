import React, { useEffect, useState } from "react";
import logo from "../../../application/assets/images/jalsalogo.png";
import { useGetPresentEvent } from "../../../application/hooks/queryHooks";
import { QRCodeCanvas } from "qrcode.react";

const ManifestSlipComponent = React.forwardRef(({ details, id }, ref) => {
  const [event, setEvent] = useState('');
  const { data, isLoading } = useGetPresentEvent();

  useEffect(() => {
    if (data !== undefined) {
      setEvent(data.data);
    }
  }, [isLoading, data]);

  const qrcode2 = (
    <QRCodeCanvas
      id="qrCode"
      value={details?.qrCode}
      size={100}
      bgColor={"white"}
      level={"H"}
    />
  );

  return (
    <div ref={ref} className="flex flex-col w-full md:items-center overflow-y-auto justify-center" id={id}>
      <div className="max-w-md mx-auto p-6 bg-white border border-gray-300 rounded-lg shadow-lg">
        {/* Logo and Event Info */}
        <div className="flex items-center justify-center mb-6">
          <img className="w-12 h-12 mr-4" src={logo} alt="Jalsa Logo" />
          <div>
            <p className="text-lg font-bold text-gray-800">
              JALSA SALANA NIGERIA {new Date(event?.eventYear).getFullYear()}
            </p>
          </div>
        </div>

        {/* Manifest Slip Title */}
        <div className="flex items-center justify-center mb-4">
          <p className="text-xl font-semibold tracking-widest text-gray-700">
            MANIFEST SLIP <p className="text-sm">{details?.referenceNumber}</p>
          </p>
        </div>

        {/* Manifest Details */}
        <div className="grid gap-4 mb-6">
          <div className="flex justify-between">
            <span className="font-semibold text-gray-600">PLATE NO:</span>
            <span className="font-medium text-gray-800">{details?.vehicleNumber}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold text-gray-600">FROM:</span>
            <span className="font-medium text-gray-800">{details?.description}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold text-gray-600">VEHICLE TYPE:</span>
            <span className="font-medium text-gray-800">{details?.vehicleTypeName}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold text-gray-600">TOTAL MEMBERS:</span>
            <span className="font-medium text-gray-800">{details?.manifestOccupants.length}</span>
          </div>
        </div>

        {/* QR Code */}
        <div className="flex justify-center mt-4">
          <div className="w-32 h-32">{qrcode2}</div>
        </div>
      </div>
    </div>
  );
});

export default ManifestSlipComponent;
