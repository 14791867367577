import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import services from "../../../ioc/services";
import MainButton from "../buttons/MainButton";
import FormikControl from "../formik/FormikControl";
import './RegisterComponent.css';
import { SpinnerCircular } from 'spinners-react';
import DisabledButton from "../buttons/DisabledButton";
import { useSelector } from "react-redux";
import { useGetGuestParticipantsByCreatorNo, useGetParticipantTypes } from "../../../application/hooks/queryHooks";
import {useNavigate} from "react-router"
import { CheckBox } from "../table/CheckBox";
import { getParticipantTypes } from "../../../infrastructure/api/user/userRequests";

const RegisterGuest = () => {
    const userId = useSelector(state => state.user.userId);
    const [isRegistering, setRegistering] = useState(false);
    const registeredGuestDetails = useGetGuestParticipantsByCreatorNo(userId);
    const user = useSelector(state => state.user);
    const userAuthLevel = user.userAuthLevel;
    const isMemberActive = userAuthLevel == "member";
    const isNazimActive = userAuthLevel == "nazim volunteer";
    const isAdminActive = userAuthLevel == "national" || userAuthLevel == "circuit" || userAuthLevel == "jamaat" || userAuthLevel == "admin";
    const navigate = useNavigate();

    const genderOption = [
        { key: "--Choose Gender--", value: '' },
        { key: "Female", value: "female" },
        { key: "Male", value: "male" },
    ]
    const [typeOption, setTypeOption] = useState([]);

    const initialValues = {
        firstname: '',
        lastname: '',
        middlename: '',
        emailAddress: '',
        phoneNumber: '',
        address: '',
        gender: '',
        participantTypeName: 'Guest',
        title: '',
    }

    const validationSchema = Yup.object({
        firstname: Yup.string().required("First name is required"),
        lastname: Yup.string().required("Last name is required"),
        // emailAddress: Yup.string().email('Must be a valid email').required("E-mail is required"),
        phoneNumber: Yup.string().required("Phone number is required"),
        address: Yup.string().required("Address is required"),
        gender: Yup.string().required("Select Gender!"),
    });

    const displayInput = [
        {
            label: "last name",
            name: "lastname",
            control: "input",
            placeholder: "Enter last name",
        },
        {
            label: "first Name",
            name: "firstname",
            control: "input",
            placeholder: "Enter first name",
        },
        {
            label: "middle Name",
            name: "middlename",
            control: "input",
            placeholder: "Enter middle name",
        },
        {
            label: "E-MAIL ADDRESS",
            name: "emailAddress",
            control: "input",
            placeholder: "Enter E-mail",
        },
        {
            label: "phone Number",
            name: "phoneNumber",
            control: "input",
            placeholder: "Input Phone No.",
        },
        {
            label: "gender",
            name: "gender",
            control: "select",
            options: genderOption,
        },
        {
            label: "title",
            name: "title",
            control: "input",
            placeholder: "Enter title"
        },
        {
            label: "address",
            name: "address",
            control: "input",
            placeholder: "Enter address",
        },

    ]
   if(isAdminActive) {
    displayInput.push(  {
        label: "participant Type",
        name: "participantTypeName",
        control: "select",
        options: typeOption,
    })
   }
    const registerGuest = (values, onSubmitProps) => {
        let ahmadiCheck = document.querySelector("#check-if-ahmadi")
        if (ahmadiCheck.checked) {
            services.toast.error("Meet your jamaat or circuit president to create an account for him/her. ");
            return;
        }
        const guestDetails = {
            participantTypeName: values.participantTypeName,
            lastName: values.lastname,
            firstName: values.firstname,
            middleName: values.middlename,
            phoneNumber: values.phoneNumber,
            email: values.emailAddress,
            gender: values.gender,
            address: values.address,
            photo: null
        }
        setRegistering(true);
        services.api.userRequests.registerGuest(guestDetails).then(res => {
           
            if (!res.data.succeeded) {
                services.toast.error(res.data.messages[0]);
            } else {
                services.toast.success(res.data.messages[0]);
                onSubmitProps.resetForm();
            }
            setRegistering(false);
        })
    }
    useEffect(() => {
        const fetchParticipantTypes = async () => {
            const participantTypeData = await getParticipantTypes();
            console.log({ participantTypeData });
            setTypeOption(participantTypeData.map(type => ({
                key: type.name,
                value: type.name,
            })));
        };
    
        fetchParticipantTypes();
    }, []);
    return (
        <>
            <div className="view-guest-btn">
                <MainButton className="flex items-center" onClick={() => {navigate(`/register/guests`)}}><span>VIEW GUESTS</span></MainButton>
            </div>
            <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={registerGuest}
    validateOnChange={false}
>
    {formik => (
        <Form className="w-full max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg space-y-6">
            <div className="space-y-4">
                <div className="flex items-center gap-2">
                    <input
                        type="checkbox"
                        id="check-if-ahmadi"
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                    <label
                        htmlFor="check-if-ahmadi"
                        className="text-lg font-semibold text-gray-800"
                    >
                        Is the guest an Ahmadi?
                    </label>
                </div>

                <FormikControl
                    key={90}
                    label="Enter the membership number"
                    name="membershipNumber"
                    placeholder="Membership Number"
                    control="input"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />

                <p className="text-red-600 font-bold italic">
                    "And do not mix the truth with falsehood or conceal the truth while you know [it]."
                    <br />
                    <span className="block text-right font-normal">Surah Al-Baqarah (2:43)</span>
                </p>

                {displayInput.map((d, index) => (
                                <FormikControl
                                    key={index * 0.5}
                                    label={d.label}
                                    name={d.name}
                                    placeholder={d.placeholder}
                                    options={d?.options}
                                    control={d.control}
                                     className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500 shadow-sm disabled:opacity-50"
                                />
                            ))}
            </div>

            <div className="flex justify-center">
                {!isRegistering ? (
                    <MainButton
                        type="submit"
                        className="w-full bg-indigo-600 text-white py-3 rounded-md hover:bg-indigo-700 transition-all duration-200 shadow-md"
                    >
                        REGISTER
                    </MainButton>
                ) : (
                    <DisabledButton
                        disabled={true}
                        className="w-full bg-gray-300 text-gray-500 py-3 rounded-md flex items-center justify-center cursor-not-allowed"
                    >
                        <span>REGISTER</span>
                        <SpinnerCircular
                            className="ml-3"
                            color="white"
                            secondaryColor="#EEF0F2"
                            size={20}
                            thickness={150}
                            enabled={isRegistering}
                        />
                    </DisabledButton>
                )}
            </div>
        </Form>
    )}
</Formik>

        </>
    );
}

export default RegisterGuest;