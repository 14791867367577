import React, { useState, useEffect } from "react";
import { InputLabel } from "../../view/components/inputs/MainInput";
import services from "../../ioc/services";
import { setLoggedInUserDetails } from "../../application/store/actions/user";
import { setLoading } from "../../application/store/actions/ui";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerRoundOutlined } from "spinners-react";
import { useLocation, useNavigate } from "react-router-dom";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [membershipNo, setMembershipNo] = useState("");
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isAdminLogin, setIsAdminLogin] = useState(
    location.pathname === "/login/admin"
  );
  const [isTajneedLogin, setIsTajneedLogin] = useState(
    location.pathname === "/login/tajneed"
  );

  const isLoggingIn = useSelector((state) => state.ui.loading);

  useEffect(() => {
    setUserName("");
    setUserPassword("");
    setMembershipNo("");
    setIsAdminLogin(location.pathname === "/login/admin");
    setIsTajneedLogin(location.pathname === "/login/tajneed");
  }, [location]);

const login = () => {
    const userDetails = { userName: isTajneedLogin ? userName : membershipNo };
    if (isAdminLogin || isTajneedLogin) userDetails.password = userPassword;

    if (
      (!isTajneedLogin && membershipNo.trim().length === 0) ||
      ((isAdminLogin || isTajneedLogin) && userPassword.trim().length === 0)
    ) {
      services.toast.error("Please Fill Necessary Fields");
    } else {
      dispatch(setLoading(true));
      if (!isTajneedLogin) {
        services.api.userRequests.login(userDetails).then((response) => {
          if (!response.status) {
            if (response.message !== undefined) {
              services.toast.error(response.message);
            } else {
              services.toast.error("An Error Occured!!");
            }
            dispatch(setLoading(false));
          } else {
            if (!isAdminLogin) services.toast.success(response.message);
            const userDetails = {
              userId: response.data.userName,
              authToken: response.data.authToken,
              userRoles: [],
            };
            if (isAdminLogin) {
              let isAuthorized =
                response.data.roles.some(
                  (r) => r.toLowerCase().indexOf("national") > -1
                ) ||
                response.data.roles.some(
                  (r) => r.toLowerCase().indexOf("circuit") > -1
                ) ||
                response.data.roles.some(
                  (r) => r.toLowerCase().indexOf("jamaat") > -1
                );

              if (!isAuthorized) {
                services.toast.error(
                  "You aren't authorized to login through this medium"
                );
                dispatch(setLoading(false));
                return;
              } else services.toast.success(response.message);
              userDetails.userRoles = response.data.roles;
            }
            dispatch(setLoggedInUserDetails(userDetails));
            dispatch(setLoading(false));
          }
        });
      } else if (isTajneedLogin) {
        services.api.userRequests.loginTajneed(userDetails).then((response) => {
          if (response?.status == true) {
            const userDetails = {
              userId: response.data.userName,
              authToken: response.data.authToken,
              userRoles: response.data.roles,
            };

            if (
              response.data.roles.some(
                (r) => r.toLowerCase().indexOf("admin") > -1
              ) ||
              response.data.roles.some(
                (r) => r.toLowerCase().indexOf("nazim volunteer") > -1
              )
            ) {
              dispatch(setLoggedInUserDetails(userDetails));
              services.toast.success(response.message);
            } else {
              services.toast.error(
                "You aren't authorized to login through this medium"
              );
            }
          } else {
            services.toast.error(response?.message);
          }
          dispatch(setLoading(false));
        });
      }
    }
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };

  return (
    <div className="flex flex-col items-center justify-center ">
      <form
        onSubmit={handleSubmit}
      >
        <h2 className="text-2xl font-bold text-center text-green-700 mb-4">
        {isAdminLogin ? "Admin Login" 
  : isTajneedLogin ? "Tajneed Login" 
  : "Member Login"}
        </h2>
        {!isTajneedLogin && (
          <>
            <InputLabel opacity="100%">MEMBERSHIP NO.</InputLabel>
            <input
              className="login-input rounded-lg p-3 border-2 border-green-400 focus:outline-none focus:border-green-600 transition duration-200 ease-in-out w-full"
              type="text"
              value={membershipNo}
              onChange={(e) => setMembershipNo(e.target.value.toUpperCase())}
              placeholder="Enter Membership No."
            />
            {isAdminLogin && (
              <div className="mt-3">
                <InputLabel opacity="100%">PASSWORD</InputLabel>
                <input
                  className="login-input rounded-lg p-3 border-2 border-green-400 focus:outline-none focus:border-green-600 transition duration-200 ease-in-out w-full"
                  type="password"
                  value={userPassword}
                  onChange={(e) => setUserPassword(e.target.value)}
                  placeholder="Enter Password"
                />
              </div>
            )}
          </>
        )}
        {isTajneedLogin && (
          <>
            <InputLabel opacity="100%">USERNAME</InputLabel>
            <input
              className="login-input rounded-lg p-3 border-2 border-green-400 focus:outline-none focus:border-green-600 transition duration-200 ease-in-out w-full"
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Enter Username"
            />
            <div className="mt-3">
              <InputLabel opacity="100%">PASSWORD</InputLabel>
              <input
                className="login-input rounded-lg p-3 border-2 border-green-400 focus:outline-none focus:border-green-600 transition duration-200 ease-in-out w-full"
                type="password"
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                placeholder="Enter Password"
              />
            </div>
          </>
        )}

        <button
          className={`w-full p-3 mt-6 text-white bg-green-600 rounded-lg hover:bg-green-700 transition duration-200 ease-in-out flex justify-center items-center ${
            isLoggingIn && "cursor-not-allowed"
          }`}
          disabled={isLoggingIn}
          onClick={(e) => handleSubmit(e)}
        >
  {isAdminLogin || isTajneedLogin ? "LOGIN" : "PROCEED"}
          {isLoggingIn && (
            <SpinnerRoundOutlined
              className="ml-3"
              color="white"
              secondaryColor="#EEF0F2"
              size={25}
              thickness={150}
            />
          )}
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
