import styled from "styled-components";
import theme from "../../../application/utils/Theme";
import MainButton from "./MainButton";


const DisabledButton = styled(MainButton)`
    opacity: 0.3;
    cursor: not-allowed;
    box-shadow: none;
`;

export default DisabledButton;