import { Formik, Form } from "formik";
import FormikControl from "../formik/FormikControl";
import './RegisterComponent.css';
import MainButton from "../buttons/MainButton";
import DisabledButton from '../buttons/DisabledButton';
import { useSelector } from "react-redux";
import { useGetMemberById } from "../../../application/hooks/queryHooks";
import { useEffect, useState } from "react";
import services from "../../../ioc/services";
import { useGetParticipantByMemberNo } from "../../../application/hooks/queryHooks";
import { SpinnerCircular, SpinnerDotted } from 'spinners-react';
import { useNavigate, useLocation } from "react-router"
import CancelRegistrationModal from "../modals/CancelRegistrationModal";
import Profile from "./Profile";

const RegisterSelf = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const activeOption = location.pathname.split("/")[2] ?? "all";
    const userId = useSelector(state => state.user.userId);
    const [isRegistering, setRegistering] = useState(false);

    const { data, isLoading } = useGetMemberById(userId);

    const [userDetails, setUserDetails] = useState({
    });

    const [refresh, setRefresh] = useState(1);

    const [isUserRegistered, setIsUserRegistered] = useState(false);

    const { data: userData, isLoading: loading } = useGetParticipantByMemberNo(userId, refresh);
    useEffect(() => {
        if (userData !== undefined && userData?.registrationNumber !== undefined) setIsUserRegistered(true);
        else setIsUserRegistered(false);

    }, [loading])


    const displayInput = [
        {
            label: "membership No.",
            name: "membershipNo",
        },
        {
            label: "last name",
            name: "lastName",
        },
        {
            label: "first Name",
            name: "firstName",
        },
        {
            label: "middle Name",
            name: "middleName",
        },
        {
            label: "circuit",
            name: "circuit",
        },
        {
            label: "jama'at",
            name: "jamaat",
        },
        {
            label: "auxilliary body",
            name: "auxilliaryBody",
        },
        {
            label: "jalsa year",
            name: "jalsaYear",
        },
    ]

    const register = () => {
        setRegistering(true);
        services.api.userRequests.registerMember(userId).then(res => {
            if (!res.data.succeeded) {
                services.toast.error(res.data?.messages[0]);
            }
            else {
                services.toast.success(res.data?.messages[0]);
                setRefresh(Math.random(100));
            }
            setRegistering(false);
        })
    }

    useEffect(() => { }, [isUserRegistered])

    useEffect(() => {
        console.log(userData, "data");
        setUserDetails({
            firstName: data?.data?.firstName?.toUpperCase() ?? '',
            lastName: data?.data?.surname?.toUpperCase() ?? '',
            middleName: data?.data?.middleName?.toUpperCase() ?? '',
            membershipNo: data?.data?.chandaNo?.toUpperCase() ?? '',
            circuit: data?.data?.circuitName?.toUpperCase() ?? '',
            jamaat: data?.data?.jamaatName?.toUpperCase() ?? '',
            jalsaYear: new Date().getFullYear().toString(),
            auxilliaryBody: data?.data?.auxillaryBodyName.toUpperCase() ?? '',
            isVolunteer: data?.data?.isVolunteer ??'',
        });

    }, [isLoading]);


    return (
        <>
            {activeOption == "cancel" && <CancelRegistrationModal setRefresh={setRefresh} />}
            {(!isUserRegistered && !loading) &&
                <Formik
                    initialValues={userDetails}
                    enableReinitialize
                    onSubmit={register}
                >
                    {formik => (

                        <Form className="w-full max-w-lg mx-auto p-6 bg-white shadow-lg rounded-lg">
    <div className="grid grid-cols-1 gap-6">
        {displayInput.map((d, index) => (
            <FormikControl
                key={index}
                label={d.label}
                name={d.name}
                disabled={true}
                control="input"
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500 shadow-sm disabled:opacity-50"
            />
        ))}
    </div>
    
    <div className="mt-6 flex justify-center">
        {isLoading ? (
            <DisabledButton 
                disabled={true} 
                className="w-full bg-gray-300 text-gray-700 cursor-not-allowed p-3 rounded-md"
            >
                REGISTER
            </DisabledButton>
        ) : (
            <MainButton 
                className="flex items-center justify-center w-full bg-green-600 hover:bg-green-700 text-white p-3 rounded-md transition-all duration-300 shadow-lg"
                type="submit"
            >
                <span>REGISTER</span>
                <SpinnerCircular 
                    className="ml-3"
                    color="white"
                    secondaryColor="#EEF0F2"
                    size={20}
                    thickness={150}
                    enabled={isRegistering} 
                />
            </MainButton>
        )}
    </div>
</Form>

                    )}
                </Formik>
            }
            {
                (isUserRegistered && !loading) &&
                <>

                <Profile member={userData}/>
                <p className=" m-4 mt-6">
                        {
                             (!userData.isVolunteer) ?
                             <a onClick={() => { navigate(`/volunteers/add/${userData?.registrationNumber}`) }} className="bg-green-600 hover:bg-green-700 text-white  font-thin p-3 rounded-md transition-all duration-300 shadow-lg">Register as Volunteer</a> :  
                             
                             <a href="https://chat.whatsapp.com/GWZ3VfPUd4O5ep6ZX1uzTT" className="text-theme-color underline font-thin cursor-pointer"></a> 
                        }
                        {/* Click Here To Join Volunteer WhatsApp group */}
                    </p>
                </>
                
            }
            {
                loading &&
                <div className=" h-full w-full flex  items-center justify-center">
                    <SpinnerDotted size={40} thickness={150} />
                </div>
            }
        </>

    );
}

export default RegisterSelf;

