import { COLUMNS } from "../../../application/utils/columns/GuestTagColumn";
import React, { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useRowSelect } from "react-table/dist/react-table.development";
import '../table/Table.css';
import { useLocation, useNavigate } from "react-router-dom";
import PaginateComponent from "../table/PaginateComponent";
import SmallButton from "../buttons/SmallButton";
import { SpinnerDotted } from 'spinners-react';
import { useSelector } from "react-redux";
import services from "../../../ioc/services";
import { MdCancel } from "react-icons/md";
import DeleteGuestModal from "../modals/DeleteGuestModal";
import { useGetGuestParticipantsByCreatorNo } from "../../../application/hooks/queryHooks";

const DisplayRegisteredGuests = () => {
    const navigate = useNavigate();
    const userId = useSelector(state => state.user.userId);
    const columns = useMemo(() => COLUMNS, []);
    const [tableData, setTableData] = useState([]);
    const location = useLocation();
    const [fullName, setFullName] = useState("");
    const [modal, setModal] = useState(false);
    const activeOption = location.pathname.split("/")[2] ?? "guests";

    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
        selectedFlatRows,
    } = useTable({
        columns,
        data: tableData
    },
        usePagination,
        useRowSelect,
        (hooks) => {

            hooks.visibleColumns.push((columns) => [
                ...columns,
            ]);
        });

    const { pageIndex, pageSize } = state;
    const [activePageOption, setActivePageOption] = useState({ length: 1 });
    const [activePageIndex, setActivePageIndex] = useState(0);
    const [prevActivePage, setPrevActivePage] = useState(activePageIndex !== 0);
    const [nextActivePage, setNextActivePage] = useState(activePageIndex + 1 < activePageOption.length);
    const [isLoading, setIsLoading] = useState(false);
    

    const goToNextPage = () => {
        setActivePageIndex(activePageIndex + 1);
    }


    const goToPreviousPage = () => {
        setActivePageIndex(activePageIndex - 1);
    }

    useEffect(() => {
        setIsLoading(true);
        services.api.userRequests.getGuestParticipantsByCreatorNo(userId, activePageIndex + 1, pageSize).then(res => {
            setActivePageOption({ length: Math.round(res?.data?.totalCount / pageSize) !== 0 ? Math.round(res.data.totalCount / pageSize) : 1 });
            setTableData(res.data.rows);
            setIsLoading(false);
        })

    }, [userId, activePageIndex, pageSize]);

    const {data, setData} = useState(useGetGuestParticipantsByCreatorNo(userId, activePageIndex + 1, pageSize));

    useEffect(() => {
        setPrevActivePage(activePageIndex !== 0);
        setNextActivePage(activePageIndex + 1 < activePageOption.length);
    }, [activePageIndex, activePageOption]);

    const deleteGuest = (jalsaNo, fullName) => {
        setFullName(fullName);
        navigate(`/register/deleteguest/${jalsaNo}`);
        setModal(true);
    }

    return (
        <>
            <div className="w-full h-[80%] md:h-full  items-center md:w-[95%] ">
                {activeOption == "deleteguest" && <DeleteGuestModal data={tableData} modal={modal} setModal={setModal} fullName={fullName} setData={setTableData} />}
                <h2 className="flex justify-center items-center my-6 font-bold tracking-[0.1em] text-[20px]">GUESTS</h2>
                <div className="md:px-5 h-[85%] overflow-y-auto">
                    {
                        tableData.length > 0 &&
                        <table  {...getTableProps()} className="table">
                            <thead className="thead">
                                {
                                    headerGroups.map((headerGroup, index) => (
                                        <tr key={index} {...headerGroup.getHeaderGroupProps()} className="tr-head">

                                            {

                                                headerGroup.headers.map((column, index) => (
                                                    <th key={index * 0.5} {...column.getHeaderProps()}>{column.render('Header')}</th>
                                                ))
                                            }
                                            <th>NAME</th>
                                            <th>ACTION</th>
                                        </tr>
                                    ))
                                }

                            </thead>
                            <tbody {...getTableBodyProps()} className="tbody">
                                {
                                    page.map((row, index) => {
                                        prepareRow(row)
                                        return (
                                            <React.Fragment key={index}>
                                                <tr key={index} {...row.getRowProps()} className="tr-body">
                                                    {
                                                        row.cells.map((cell, index) => {
                                                            return <td key={index} {...cell.getCellProps()} >{cell.render('Cell')}</td>
                                                        })
                                                    }
                                                    <td>{`${row.original.lastName} ${row.original.firstName}`}</td>
                                                    <td>
                                                        <div className="flex" >
                                                            <SmallButton color="red" background="red" onClick={() => {
                                                                setFullName(row?.original.registrationNumber);
                                                                deleteGuest(row?.original?.registrationNumber,`${row?.original?.lastName} ${row?.original?.firstName}`);
                                                            }}>
                                                                <MdCancel size={20} onClick={() => {
                                                                    setFullName(row?.original.registrationNumber);
                                                                    deleteGuest(row?.original?.registrationNumber);
                                                                }} />
                                                            </SmallButton>
                                                        </div>

                                                    </td>
                                                </tr>
                                            </React.Fragment>

                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    }
                    {
                        tableData.length === 0 && !isLoading &&
                        <div className="flex  h-full items-center justify-center">
                            <span className="opacity-[49%] tracking-[0.1em] font-bold  text-[12px] md:text-[14px]">NO GUEST REGISTERED YET!</span>
                        </div>
                    }
                    {
                        isLoading &&
                        <div className=" h-full w-full flex items-center justify-center">
                            <SpinnerDotted size={40} thickness={150} />
                        </div>
                    }
                </div>
                <PaginateComponent pageIndex={activePageIndex} pageOptions={activePageOption} canNextPage={nextActivePage} canPreviousPage={prevActivePage} previousPage={goToPreviousPage} nextPage={goToNextPage} pageSize={pageSize} setPageSize={setPageSize} />
            </div >
        </>
    );
}

export default DisplayRegisteredGuests;