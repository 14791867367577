export const COLUMNS = [

    {
        Header: "REGISTRATION NO",
        accessor: "registrationNumber",
        Cell: ({ value }) => {
            return value?.toUpperCase();
        }
    },
    {
        Header: "PARTICIPANT TYPE",
        accessor: "participantType",
        Cell: ({ value }) => {
            return value?.toUpperCase();
        }
    }
   
]