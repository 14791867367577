import theme from "../../../application/utils/Theme";


const PaginateComponent = ({pageIndex, pageOptions, test, canNextPage, canPreviousPage, previousPage, nextPage, pageSize, setPageSize}) => {

    const noOfEntries = [1, 10, 20, 30, 40, 50]
    return ( 
        <div className="relative flex  justify-center items-center">
                <div className="flex  justify-center items-center fixed bottom-[5rem] md:bottom-6">
                    <div className="text-[12px] md:text-[16px]">
                        <span>Page <strong>{pageIndex + 1} of {pageOptions.length}</strong></span>
                    </div>
                    <div className="mx-2 md:mx-5">
                        <button className={`next-prev bg-theme-color ${!canPreviousPage ? "inactive" : ""}`} disabled={!canPreviousPage} onClick={() => previousPage()}>PREV</button>
                        <button className={`next-prev bg-theme-color ${!canNextPage ? "inactive" : ""}`} disabled={!canNextPage} onClick={() => nextPage()} >NEXT</button>

                    </div>
                    <div>
                        <select value={pageSize} onChange={e => setPageSize(Number(e.target.value))} className="outline-none p-[0.5rem]">
                            {
                                noOfEntries.map(m =>
                                    <option key={m} value={m}>{`Show ${m}`}</option>
                                )
                            }
                        </select>
                    </div>
                </div>
            </div>
     );
}
 
export default PaginateComponent;