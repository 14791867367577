
const theme = {
    color: "#16A34A",
    textColor: "#fff",
    backgroundColor: "#EEF0F2",
    grayColor: "#000000",
    breakPoint :{
        'tablet' : "768px",
        
    }
}


export default theme;
