import React from "react";

const Profile = ({ member }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6 w-full">
      <div className="bg-theme-color p-4 rounded-t-lg">
        <h2 className="text-white text-2xl font-bold">{`${member.firstName} ${member.lastName}`}</h2>
        <p className="text-blue-200">{member.jamaatName}</p>
      </div>

      <div className="p-4 space-y-2">
        <p className="text-gray-700">
          <span className="font-semibold">Membership Number:</span> {member.membershipNumber}
        </p>
        <p className="text-gray-700">
          <span className="font-semibold">Registration Number:</span> {member.registrationNumber}
        </p>
        <p className="text-gray-700">
          <span className="font-semibold">Phone:</span> {member.phoneNumber}
        </p>
        <p className="text-gray-700">
          <span className="font-semibold">Email:</span> {member.email}
        </p>
        <p className="text-gray-700">
          <span className="font-semibold">Address:</span> {member.address}
        </p>
        <p className="text-gray-700">
          <span className="font-semibold">Circuit:</span> {member.circuitName}
        </p>
        <p className="text-gray-700">
          <span className="font-semibold">Auxiliary Body:</span> {member.auxiliaryBody}
        </p>
        <p className="text-gray-700">
          <span className="font-semibold">Status:</span> {member.status === 2 ? "CheckedIn" : "Registered"}
        </p>
        <p className="text-gray-700">
          <span className="font-semibold">Volunteered:</span> {member.isVolunteer ? "Yes" : "No"}
        </p>
      </div>
    </div>
  );
};

export default Profile;
