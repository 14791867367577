import { useLocation } from "react-router-dom";
import AddManifestComponent from "./AddEditManifestComponent";
import DisplayManifestComponent from "./DisplayManifestsComponent";
import DisplayManifestDetails from "./DisplayManifestDetails";
import ManifestMembers from "./ManifestMembersComponent";
import NavigationLayout from "../../layout/NavigationLayout";
import useAuthentication from "../../../application/hooks/useAuthentication";
import InputReferenceComponent from "./InputReferenceComponent";
import ViewManifestSlip from "./ViewManifestSlipComponent";

const ManifestControlComponent = () => {
    useAuthentication();
    const location = useLocation();
    const activeOption = location.pathname.split("/")[2] ?? "all";


    return (
        <NavigationLayout>
            {
                activeOption == "all" && <DisplayManifestComponent  />
            }
            {
                (activeOption == "add" || activeOption == "edit") && <AddManifestComponent />
            }
            {
                activeOption == "details" && <DisplayManifestDetails />
            }
            {
                activeOption == "participants" && <ManifestMembers />
            }
            {
                activeOption == "reference" && <InputReferenceComponent/>
            }
            {
                activeOption == "print" && <ViewManifestSlip/>
            }
        </NavigationLayout>
    );
}

export default ManifestControlComponent;