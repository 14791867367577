export const COLUMNS = [

    {
        Header: "Circuit",
        accessor: "circuitName",
    
    },
   
    {
        Header: "Total Member",
        accessor: "totalMember",
    
    },
   
    {
        Header: "Total Registered Members",
        accessor: "registrationCount",
    
    },
   
    {
        Header: "Total Registered Guests",
        accessor: "totalGuests",
    
    },
   
    {
        Header: "Registered Percentage(%)",
        accessor: "registeredPercentage",    
    },
   
]