import { act } from "@testing-library/react";
import { useLocation } from "react-router-dom";
import useAuthentication from "../../application/hooks/useAuthentication";
import AddVolunteer from "../components/volunteer/AddVolunteer";
import ListVolunteer from "../components/volunteer/ListVolunteers";
import NavigationLayout from "../layout/NavigationLayout";

const VolunteerContainer = () => {
    useAuthentication();
    const location = useLocation();
    const activeOption = location.pathname.split("/")[2] ?? "all";
    return (
        <NavigationLayout>
            {
                (activeOption == 'add' || activeOption == "edit") && <AddVolunteer/>

            }
            {
                (activeOption == "all" || activeOption == "cancel")  && <ListVolunteer/>
            }
        
        </NavigationLayout>
    );
}

export default VolunteerContainer;