import styled from "styled-components";
import theme from "../../../application/utils/Theme";

const SmallButton = styled.button`
    color:  ${props => props.color ? props.color : theme.color};
    padding: 0.5em;
    font-weight: 500;
    &&:hover {
        transition: all 0.3s;
        color: white;
        background-color: ${props => props.background ? props.background : theme.color};
    }
`

export default SmallButton;