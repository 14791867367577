export const COLUMNS = [

    {
        Header: "USER NAME",
        accessor: "userName",
        Cell: ({ value }) => {
            return value.toUpperCase();
        }
    },
    {
        Header: "ROLE",
        accessor: "roles",
        Cell: ({ value }) => {
            return value[0]?.name?.toUpperCase() ?? "-";
        }
    },
   
]