import { useParams } from "react-router-dom";
import theme from "../../../application/utils/Theme";
import MainButton from "../buttons/MainButton";
import { FaPrint } from 'react-icons/fa';
import { TagComponent } from "./TagComponent";
import usePrintTag from "../../../application/hooks/usePrintTag";
import { useGetParticipantByRegNo } from "../../../application/hooks/queryHooks";
import { SpinnerCircular } from 'spinners-react';
import { useEffect, useState } from "react";

const ViewGuestTag = () => {

    const params = useParams();

    const [participantDetails, setParticipantDetails] = useState({});
    const [currentId, setCurrentId] = usePrintTag();
    const { data, isLoading } = useGetParticipantByRegNo(params.registrationId);

    useEffect(() => {
        if (data !== undefined) {
            setParticipantDetails(data);
            console.log(data, "parttttttttttttttttttttttttttttttttttt")
        }
    }, [isLoading]);

    return (
        <div className="flex flex-col items-center h-full w-full">
            {
                participantDetails.lastName !== undefined &&
                <>
                    <TagComponent id="print" details={participantDetails}  />

                    <div className="flex mt-4">
                        <MainButton onClick={() => setCurrentId("print")} background={theme.textColor} color={theme.color}>
                            <div className="flex items-center">
                                <FaPrint className="mr-2" />PRINT
                            </div>
                        </MainButton>
                    </div>
                </>
            }
            {
                isLoading &&
                <div className=" h-full w-full flex items-center justify-center">
                    <SpinnerCircular size={40} thickness={150} />
                </div>
            }
            {
                
                (participantDetails.lastName == undefined && !isLoading) &&
                <div className="flex  h-full items-center justify-center">
                    <span className="text-[red] tracking-[0.1em] font-bold  text-[12px] md:text-[16px]">
                        {`GUEST WITH REGISTRATION ID-${params.registrationId.toUpperCase()} NOT FOUND!`}
                    </span>
                </div>
            }

        </div>
    );
}

export default ViewGuestTag;