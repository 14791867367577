import styled from "styled-components";
import theme from "../../../application/utils/Theme";
import { Field, ErrorMessage } from "formik";
import { TextError } from "./MainInput";
import { FaSearch } from 'react-icons/fa';


const InputField = styled.input`
  width: 150px;
  height: 35px;
  padding-left: 30px;
  outline:none;
  @media (min-width: ${theme.breakPoint['tablet']}) {
    width: 221.48px;
   height: 37px;
  }
  `
const style = {
  display: "flex",
  alignItems: "center",
  color: "gray"
}

const SearchInput = (props) => {
  const {setFilter, filter, type, placeholder, ...rest } = props;
  return (
    <div className="flex items-center">
      <FaSearch size={20} className="mx-2 opacity-[49%]"/>
      <InputField value={filter} type="search" onChange={e=> {setFilter(e.target.value)}} {...rest}placeholder={placeholder ? placeholder : "Search"} />

    </div>
  );
}

export default SearchInput;