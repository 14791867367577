import React from "react";
import LoginForm from '../../container/LoginForm'

const LoginModal = ({ setIsModalActive, isLoading }) => {
  const minimizeModal = (e) => {
    if (e.target.classList.contains("modal")) {
      setIsModalActive(false);
    }
  };

  return (
    <div
      className="fixed flex justify-center items-center modal bg-[#00000048] h-[100vh] w-[100vw] top-0 left-0 z-20"
      onClick={minimizeModal}
    >
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md space-y-6">
        <LoginForm />
        <button
          className="mt-4 px-4 py-2 bg-gray-500 text-white rounded"
          onClick={() => setIsModalActive(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default LoginModal;
