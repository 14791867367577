import { useLocation, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import MainButton from "../buttons/MainButton";
import FormikControl from "../formik/FormikControl";
import { FaEdit, FaPlus } from "react-icons/fa";
import { useGetAllRoles } from "../../../application/hooks/queryHooks";
import { useEffect, useState } from "react";
import services from "../../../ioc/services";
import { useNavigate } from "react-router-dom";
import { SpinnerCircular } from 'spinners-react';
import DisabledButton from "../buttons/DisabledButton";

const AddEditUserComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const activeOption = location.pathname.split("/")[2];
    const isEdit = activeOption == 'edit'

    const [roleOption, setRoleOption] = useState([{ key: "--Choose Role--", value: '' }]);

    const { data: roleData, isLoading: isRoleLoading } = useGetAllRoles();
    const [initialValues, setInitialValues] = useState({
        firstname: '',
        lastname: '',
        username: '',
        password: '',
        role: '',
        phoneno: '',
    });

    const validationSchema = Yup.object({
        firstname: Yup.string().required("First name is required"),
        lastname: Yup.string().required("Last name is required"),
        username: Yup.string().required("User name is required"),
        password: Yup.string().required("Password is required"),
        phoneno: Yup.string().required("Phone number is required"),
        role: Yup.string().required("Select Role!"),
    });

    const displayInput = [
        {
            label: "last name",
            name: "lastname",
            control: "input",
            placeholder: "Enter last name",
        },
        {
            label: "first Name",
            name: "firstname",
            control: "input",
            placeholder: "Enter first name",
        },
        {
            label: "User Name",
            name: "username",
            control: "input",
            placeholder: "Enter user name",
        },
        {
            label: "Password",
            name: "password",
            control: "input",
            type: "password",
            placeholder: "Enter your password",
        },
        {
            label: "phone number",
            name: "phoneno",
            control: "input",
            placeholder: "Enter your phone number",
        },
        {
            label: "role",
            name: "role",
            control: "select",
            options: roleOption,
        },

    ]

    const [isAdding, setIsAdding] = useState(false);

    const submit = (values) => {
        const userDetail = {
            firstName: values.firstname,
            lastName: values.lastname,
            userName: values.username,
            password: values.password,
            phoneNumber: values.phoneno,
            roleName: values.role,
        }

        if (!isEdit) {
            setIsAdding(true);
            services.api.userRequests.addUser(userDetail).then(response => {
                if (response.status == 200) {
                    services.toast.success(response.data.message);
                    navigate("/users");
                }
                else services.toast.error(response.message);
                setIsAdding(false);
            })
        }
    }

    useEffect(() => {
        if (roleData !== undefined && roleOption.length == 1) {
            const modifiedData = roleData.map(r => ({ key: r.roleName, value: r.roleName, }));
            setRoleOption([...roleOption, ...modifiedData])
        }
    }, [isRoleLoading])

    useEffect(() => {
        if (isEdit) {
            services.api.userRequests.getUsersByUserName(params.username).then(res => {
                const user = {
                    firstname: res.firstName,
                    lastname: res.lastName,
                    username: res.userName,
                    password: '',
                    role: res?.roles[0]?.name,
                    phoneno: '',
                }
                setInitialValues(user);
            })
        }
    }, [])

    return (
        <div>
            <h2 className="flex justify-center items-center my-6 font-bold text-[20px]">{isEdit ? <FaEdit className="mx-2" /> : <FaPlus className="mx-2" />} USER</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                enableReinitialize
                onSubmit={submit}
            >
                {formik => (

                    <Form>
                        <div className="register-component grid-column" >
                            {displayInput.map((d, index) => (
                                <FormikControl
                                    key={index * 0.5}
                                    label={d.label}
                                    name={d.name}
                                    placeholder={d.placeholder}
                                    options={d?.options}
                                    control={d.control}
                                    type={d?.type}
                                />
                            ))}

                        </div>
                        <div className="register-btn">
                            {
                                !isAdding &&
                                <MainButton className="flex items-center" type="submit" > <span>{isEdit ? "EDIT" : "ADD "} USER</span> </MainButton>
                            }
                            {
                                isAdding &&
                                <DisabledButton disabled={true} className="flex items-center"> <span>{isEdit ? "EDIT" : "ADD "} USER</span> <SpinnerCircular className="ml-3" color="white" secondaryColor="#EEF0F2" size={20} thickness={150} enabled={isAdding} /> </DisabledButton>
                            }
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default AddEditUserComponent;

