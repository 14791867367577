import { useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTable, useGlobalFilter, usePagination, useRowSelect } from "react-table/dist/react-table.development";
import { COLUMNS } from "../../../application/utils/columns/ManifestMemberColumn";
import PaginateComponent from "../table/PaginateComponent";
import services from "../../../ioc/services";
import { useGetManifestByReferenceNo } from "../../../application/hooks/queryHooks";

const ManifestMembers = () => {
    const params = useParams();
    const columns = useMemo(() => COLUMNS, []);
    const { data: manifestData, isLoading } = useGetManifestByReferenceNo(params.referenceNumber);
    const [data, setData] = useState([]);


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
    } = useTable({
        columns,
        data
    },
        useGlobalFilter,
        usePagination,
        useRowSelect,

    )

    const { pageIndex, pageSize } = state;
    console.log("hjdfhjjdjkjdsjkjdsjjdsjjdjsjdjks",manifestData);
    useEffect(() => {
        if (manifestData !== undefined && data.length == 0) {
            let occupantDetail = [];
            manifestData.data.manifestOccupants.forEach(o => {
                services.api.userRequests.getParticipantByRegNo(o.jalsaRegNo).then(res => {
                    occupantDetail.push(res);
                    setData([...data, ...occupantDetail]);
                }).catch(err => {
                    return err;
                })

            })

        }

    }, [isLoading])

    return (
        // <h1>Here</h1>
        <div className="w-full items-center h-[85%] md:h-[95%]">
            <span className="opacity-[49%] tracking-[0.2em] font-bold flex justify-center md:my-[20px] text-[16px] md:text-[20px]">MANIFESTS MEMBERS</span>
            <div className="md:px-5 h-[90%]  overflow-auto md:overflow-x-hidden">
                <table  {...getTableProps()} className="table md:ml-[20px]">
                    <thead className="thead">
                        {
                            headerGroups.map((headerGroup, index) => (
                                <tr key={index} {...headerGroup.getHeaderGroupProps()} className="tr-head">
                                    <th>NAME</th>
                                    {
                                        headerGroup.headers.map((column, index) => (
                                            <th key={index * 0.5} {...column.getHeaderProps()}>{column.render('Header')}</th>
                                        ))
                                    }
                                </tr>
                            ))
                        }

                    </thead>
                    <tbody {...getTableBodyProps()} className="tbody">
                        {
                            page.map((row, index) => {
                                prepareRow(row)
                                return (
                                    <>
                                        <tr key={index} {...row.getRowProps()} className="tr-body ">
                                            <td>{row.original.lastName} {row.original.firstName}</td>
                                            {
                                                row.cells.map((cell, index) => {
                                                    return <td key={index} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                })
                                            }

                                        </tr>
                                    </>

                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <PaginateComponent pageIndex={pageIndex} pageOptions={pageOptions} canNextPage={canNextPage} canPreviousPage={canPreviousPage} previousPage={previousPage} nextPage={nextPage} pageSize={pageSize} setPageSize={setPageSize} />
        </div >
    );
}

export default ManifestMembers;
