import React, { useState, useEffect } from "react";

const Countdown = () => {
  // Calculate time left function without months and weeks
  const calculateTimeLeft = () => {
    const targetDate = new Date("2024-12-20T00:00:00"); // Target date
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      timeLeft = { days, hours, minutes, seconds };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <div className="grid grid-cols-2 gap-6 justify-center w-full my-8 px-8 md:flex md:space-x-6">
  {/* Render countdown units dynamically */}
  {Object.entries(timeLeft).map(([unit, value]) => (
    <CountdownUnit key={unit} value={value} unit={unit.toUpperCase()} />
  ))}
</div>
  );
};

// Single countdown unit component
const CountdownUnit = ({ value, unit }) => {
  return (
    <div className="flex flex-col items-center bg-white p-8 w-40 rounded-2xl shadow-lg">
      <span className="text-4xl font-extrabold text-green-600">{String(value).padStart(2, "0")}</span> {/* Countdown value */}
      <span className="text-lg font-semibold text-gray-600 mt-2">{unit}</span> {/* Unit label */}
    </div>
  );
};

export default Countdown;
