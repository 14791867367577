import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../store/actions/ui";

const useSelectAuthorization = (roles) => {

    const dispatch = useDispatch();

    const userAuthLevel = useSelector(state => state.user.userAuthLevel);
    const isAuthorized = roles.some(r=> userAuthLevel == r );

    useEffect(() => {
        if (!isAuthorized) {
            dispatch(setLoading(false))
            window.history.back();
            return;
        }
    }, [])
    return null;
};

export default useSelectAuthorization;