export const COLUMNS = [

    {
        Header: "DEPARTMENT NAME",
        accessor: "departmentName",
    },
    {
        Header: "PHONE NO",
        accessor: "phoneNumber",
    },
   
]