import React, { useMemo, useEffect, useState } from "react";
import { useTable, useGlobalFilter, usePagination, useRowSelect } from "react-table/dist/react-table.development";
import { COLUMNS } from "../../../application/utils/columns/VolunteerColumn";
import '../table/Table.css';
import { FaEdit, FaFileDownload, FaPlus } from 'react-icons/fa';
import MainButton from "../buttons/MainButton";
import services from "../../../ioc/services";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PaginateComponent from "../table/PaginateComponent";
import { SpinnerDotted } from 'spinners-react';
import LabelledInputField from "../inputs/LabelledInputField";
import { useGetAllDepartments, useGetParticipantByMemberNo, useGetAllVolunteersWithoutPagination } from "../../../application/hooks/queryHooks";
import { useSelector } from "react-redux";
import SmallButton from "../buttons/SmallButton";
import { MdCancel } from "react-icons/md";
import CancelVolunteerServiceModal from "../modals/CancelVolunteerServiceModal";
import ExportCSV from './ExportCSV';
import {ExportExcel} from './ExportExcel';

const ListVolunteer = () => {
    const [data, setData] = useState([]);
    const columns = useMemo(() => COLUMNS, []);
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const userAuthLevel = user.userAuthLevel;
    const [modal, setModal] = useState(false);
    const location = useLocation();
    const activeOption = location.pathname.split("/")[2] ?? "all";
    const [volunteerRegNo, setVolunteerRegNo] = useState("");
    const [vdata, setUserData] = useState([]);

    const userId = useSelector(state => state.user.userId);
    

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
    } = useTable({
        columns,
        data,
    },
        useGlobalFilter,
        usePagination,
        useRowSelect,
    )

    const { pageIndex, pageSize } = state;
    const [activePageOption, setActivePageOption] = useState({ length: 1 });
    const [activePageIndex, setActivePageIndex] = useState(0);
    const [prevActivePage, setPrevActivePage] = useState(activePageIndex !== 0);
    const [nextActivePage, setNextActivePage] = useState(activePageIndex + 1 < activePageOption.length);
    const { data: departmentData, isLoading: departmentLoading } = useGetAllDepartments();
    const [departmentOption, setDepartmentOption] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [deptName, setDeptName] = useState("");
    const [deptDesc, setDeptDesc] = useState("");
    const fileName = "jalsavolunteers";

    const { data: userData, isLoading: loading } = useGetParticipantByMemberNo(userId);


    const goToNextPage = () => {
        setActivePageIndex(activePageIndex + 1);
    }

    const goToPreviousPage = () => {
        setActivePageIndex(activePageIndex - 1);
    }

    const editVolunteer = (jalsaNo) => {
        navigate(`edit/${jalsaNo.replace("/", "-")}`);
    };


    const cancelVolunteer = (jalsaNo) => {
        setVolunteerRegNo(jalsaNo);
        navigate(`cancel/${jalsaNo.replace("/", "-")}`);
        setModal(true);
    }

    useEffect(() => {
        setIsLoading(true);
        if (userAuthLevel !== "member") {
            services.api.userRequests.getAllVolunteers(activePageIndex + 1, pageSize).then(res => {
                setActivePageOption({ length: Math.round(res?.totalCount / pageSize) !== 0 ? Math.round(res?.totalCount / pageSize) : 1 });
                setData(res?.rows)
                setIsLoading(false);
            });
        }
        else {
            services.api.userRequests.getVolunteerByJalsaNo(userData?.registrationNumber).then(res => {
                if(res?.data!== undefined) setData([res?.data]);
                
                setIsLoading(false);
            })
        }

    }, [activePageIndex, pageSize])

    useEffect(() => {
        services.api.userRequests.getAllVolunteersWithoutPagination().then(res => {
            setUserData(res);
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        setPrevActivePage(activePageIndex !== 0);
        setNextActivePage(activePageIndex + 1 < activePageOption.length);
    }, [activePageIndex, activePageOption]);

    const handleDepartmentChange = (value) => {
        setSelectedDepartment(value);
        if (value.toLowerCase() === 'all') {
            setData(data);
            return;
        }
        services.api.userRequests.getVolunteersByDepartment(value, activePageIndex + 1, pageSize).then(res => {
            setData(res.rows);
        })

    }

    useEffect(() => {
        if (departmentData !== undefined) {
            setDepartmentOption([{ name: 'All', departmentId: '' }, ...departmentData]);
        }
    }, [departmentData, departmentLoading])


    return (
        <div className="w-full items-center h-[85%] md:h-[95%] ">
            {activeOption == "cancel" && <CancelVolunteerServiceModal modal={modal} deptDesc={deptDesc} setModal={setModal} volunteerRegNo={volunteerRegNo} data={data} setData={setData} />}
            <span className="opacity-[49%] tracking-[0.2em] font-bold flex justify-center md:my-[20px] text-[16px] md:text-[20px]"> {
                userAuthLevel === "member" ? "VOLUNTEER" : "VOLUNTEERS"
            }</span>

            {
                userAuthLevel !== "member" &&
                <div className="flex items-center">
                    <div className="flex-1">
                    </div>
                    <div className="w-[25%] ml-2">
                    <ExportExcel apiData={vdata} fileName={fileName} />
                    </div>
                    <div className="w-[25%] mr-2">
                        <LabelledInputField type="select" label="Role" id="Role"
                            value={selectedDepartment}
                            onChange={(e) => handleDepartmentChange(e.target.value)}
                            data={departmentOption.map(department => (
                                {
                                    value: department.name,
                                    text: department.name
                                }
                            ))} />
                    </div>
                    <MainButton onClick={() => navigate("add")} className="h-10">
                        <span className="flex items-center"><FaPlus className="mr-2" /> Add Volunteer</span>
                    </MainButton>
                </div>

            }
          

            <div className="md:px-5 h-[70%] overflow-auto md:overflow-x-hidden">
                {
                    
                    (!isLoading && data?.length > 0) &&
                    <table  {...getTableProps()} className="table md:ml-[20px]">
                        <thead className="thead">
                            {
                                headerGroups.map((headerGroup, index) => (
                                    <tr key={index} {...headerGroup.getHeaderGroupProps()} className="tr-head">
                                        <th>NAME</th>
                                        {
                                            headerGroup.headers.map((column, index) => (
                                                <th key={index * 0.5} {...column.getHeaderProps()}>{column.render('Header')}</th>
                                            ))
                                        }
                                    </tr>
                                ))
                            }

                        </thead>
                        <tbody {...getTableBodyProps()} className="tbody">
                            {
                                page.map((row, index) => {
                                    prepareRow(row)
                                    return (
                                        <React.Fragment key={index} >
                                            <tr {...row.getRowProps()} className="tr-body ">
                                                <td>{`${row.original.lastName} ${row.original.firstName}`}</td>
                                                {
                                                    row.cells.map((cell, index) => {
                                                        return <td key={index} {...cell.getCellProps()} >{cell.render('Cell')}
                                                        </td>

                                                    })
                                                }
                                                <td>
                                                    <div className="flex gap-x-2">
                                                        <SmallButton onClick={() => {
                                                            setVolunteerRegNo(row?.original?.jalsaNo);
                                                            setDeptName(row?.original?.departmentName);
                                                            editVolunteer(row?.original?.jalsaNo);
                                                        }
                                                        }>
                                                            <FaEdit onClick={() => {
                                                                setVolunteerRegNo(row?.original?.jalsaNo);
                                                                setDeptName(row?.original?.departmentName)
                                                                editVolunteer(row.original.jalsaNo)
                                                            }
                                                            } />
                                                        </SmallButton>
                                                        <SmallButton color="red"
                                                            onClick={() => {
                                                                setVolunteerRegNo(row?.original?.jalsaNo);
                                                                setDeptDesc(`${row.original.lastName} ${row.original.firstName} for ${row?.original?.departmentName}`);
                                                                cancelVolunteer(row.original.jalsaNo)
                                                            }
                                                            }
                                                            background="red" >
                                                            <MdCancel onClick={() => {
                                                                setVolunteerRegNo(row?.original?.jalsaNo);
                                                                setDeptDesc(`${row.original.lastName} ${row.original.firstName} for ${row?.original?.departmentName}`);
                                                                editVolunteer(row.original.jalsaNo)
                                                            }
                                                            } />
                                                        </SmallButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>

                                    )
                                })
                            }
                        </tbody>
                    </table>
                    
                }
                {
                    data?.length === 0 && !isLoading &&
                    <div className="flex  h-full items-center justify-center">
                        <span className="opacity-[49%] tracking-[0.1em] font-bold  text-[12px] md:text-[14px]">{userAuthLevel === "member" ? 
                        <p>
                            YOU HAVEN'T VOLUNTEERED <a onClick={() => { navigate(`/volunteers/add/${userData?.registrationNumber}`) }} className="text-theme-color underline cursor-pointer">Register as Volunteer</a>
                        </p> : "NO VOLUNTEERS"}</span>
                    </div>
                }
                {
                    isLoading &&
                    <div className=" h-full w-full flex items-center justify-center">
                        <SpinnerDotted size={40} thickness={150} />
                    </div>
                }

            </div>
            <PaginateComponent pageIndex={activePageIndex} pageOptions={activePageOption} canNextPage={nextActivePage} canPreviousPage={prevActivePage} previousPage={goToPreviousPage} nextPage={goToNextPage} pageSize={pageSize} setPageSize={setPageSize} />
        </div >
    );
}

export default ListVolunteer;
