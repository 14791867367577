import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScanLayout from "../layout/ScanLayout";

const ScanPage = () => {
  const navigate = useNavigate();
  const userId = JSON.parse(localStorage.getItem("userDetails"))?.userId;
  const user = useSelector((state) => state.user);
  const userIdStore = user.userId;
  const userAuthLevel = user.userAuthLevel;

  const loc = window.location.href.split("=");
  console.log(loc, "location");
const message = loc[1];
const decodedMessage = decodeURIComponent(message);
  return (
    <ScanLayout>
      <span className="text-2xl font-[700] tracking-[0.2rem] opacity-[49%]" >{decodedMessage}</span>
    </ScanLayout>
  );
};

export default ScanPage;
