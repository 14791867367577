import { useState } from "react";
import { SpinnerCircular } from "spinners-react";
import DisabledButton from "../buttons/DisabledButton";
import MainButton from "../buttons/MainButton";
import LabelledInputField from "../inputs/LabelledInputField";

const RegisterAsVolunteer = () => {
    const [selectedVolunteerRole, setSelectedVolunteerRole] = useState('');
    const roles = [
        {
            id: 1,
            name: "security"
        },
        {
            id: 2,
            name: "traffic"
        }]

    const handleVolunteerRoleChange = (value) => {
        setSelectedVolunteerRole(value);
    }

    const style = "w-[50%]";

    return (
        <>
            <div className="my-10">
                <h1 className="font-bold text-[20px] text-center mb-7">VOLUNTEER</h1>
                <LabelledInputField value={selectedVolunteerRole} type="select" id="volunteer" className={style} onChange={(e) => handleVolunteerRoleChange(e.target.value)}
                    data={roles.map(role => (
                        {
                            value: role.name,
                            text: role.name.toUpperCase()
                        }
                    ))} />
            </div>

            <div className="register-btn">
                <MainButton className="flex items-center" type="submit" > <span>REGISTER</span> </MainButton>
            </div>
        </>
    );
}

export default RegisterAsVolunteer;