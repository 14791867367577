import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import logo from "../../application/assets/images/jalsalogo.png";
import "./LoginLayout.css";
import { FaUserCheck } from "react-icons/fa";

const LoginLayout = ({ children }) => {

    const location = useLocation();

    const test = () => {
        if (location.pathname === '/login/admin') return "Admin";
        else if (location.pathname === '/login/tajneed') return "Tajneed";
        else return "Member";
    }

    return (
        <div >
            <div className="login-component">
                <div className="login-component-containerOne">
                    <div className="md:flex md:flex-col md:items-start ">
                       
                        <div className="flex flex-col items-center md:items-end md:flex md:flex-row">
                            <img src={logo} alt="img" className="h-[70px] w-[60px] md:h-[80px] md:w-[70px]" />
                            <span className="text my-5">JALSA SALANA NIGERIA</span>
                        </div>
                        <div className="w-full flex md:block md:mt-4 items-center justify-center">
                            <span className="light-text">Scan Page</span>
                        </div>
                    </div>
                </div>
                <div className="login-component-containerTwo ">
                    <div className="background-white ">
                        <div className=" w-full md:w-[80%] h-full flex items-center mt-[2.5rem] md:mt-0">
                            <div className="h-auto w-full flex-col">
                                <div className="hidden md:block">
                                {/* <FaUserCheck /> */}
                                </div>
                                <div>
                                {/* <FaUserCheck  style={{fontSize: "2pc"}}/> */}
                                    {children}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default LoginLayout;