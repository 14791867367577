import { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router";
import useAuthentication from "../../../application/hooks/useAuthentication";
import useAuthorization from "../../../application/hooks/useAuthorization";
import MainButton from "../buttons/MainButton";

const InputReferenceComponent = () => {
    useAuthorization();

    const [referenceNo, setReferenceNo] = useState("");
    const navigate = useNavigate();

    const handleSubmit = (param) => {
        if (referenceNo.trim().length > 0) navigate(`/manifest/${param}/${referenceNo.trim()}`);

    }

    return (
        <div className="h-full flex flex-col items-center">
            <div className="text-center font-bold tracking-[0.1rem] mt-10">
                <h2>EDIT MANIFEST BY REFERENCE</h2>
            </div>
            <div className="mt-[100px] flex flex-col w-[90%] md:w-[55%]">
                <label className="font-bold text-sm tracking-[0.1rem] opacity-[49%]">REFERENCE NO.</label>
                <input className=" h-10 outline-0 px-2" value={referenceNo} placeholder="Input Manifest Reference No."
                    onChange={(e) => { setReferenceNo(e.target.value.toLowerCase()) }} />
                <div className="flex mt-2  items-center justify-center">
                    <MainButton className=" whitespace-nowrap" onClick={() => handleSubmit("edit")}>
                        <span>EDIT MANIFEST</span>
                        
                    </MainButton>
                    <div className="mx-2"></div>
                    <MainButton className=" whitespace-nowrap" onClick={() => handleSubmit("participants")}>
                        <span>VIEW PARTICIPANTS</span>
                    </MainButton>
                </div>

            </div>
        </div>
    );
}

export default InputReferenceComponent;