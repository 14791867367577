import logo from "../../application/assets/images/jalsalogo.png";
import { FaUserCircle, FaUserPlus, FaCar, FaTags, FaRegBuilding, FaArrowAltCircleLeft, FaArrowAltCircleRight, FaHome, FaUsers } from 'react-icons/fa';
import { MdVolunteerActivism } from 'react-icons/md';
import { useState } from "react";
import './NavigationLayout.css'
import { useNavigate, useLocation, BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedInUserDetails } from '../../application/store/actions/user';
import { setLoading } from '../../application/store/actions/ui';

const NavigationLayout = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const userAuthLevel = user.userAuthLevel;
    const isMemberActive = userAuthLevel == "member";
    const isNazimActive = userAuthLevel == "nazim volunteer";
    const isAdminActive = userAuthLevel == "national" || userAuthLevel == "circuit" || userAuthLevel == "jamaat" || userAuthLevel == "admin";
    const isTajneedActive = userAuthLevel == "admin";
    const [isLogoutPopActive, setIsLogoutPopActive] = useState(false);
    const activeSideOption = location.pathname.split("/")[1];

    const navOptions = [
        {
            displayText: "dashboard",
            action: "dashboard",
            isActive: isAdminActive,
            icon: FaHome,
        },
        {
            displayText: "register",
            action: "register",
            isActive: isMemberActive || isAdminActive,
            icon: FaUserPlus,
        },
        {
            displayText: "manifest",
            action: "manifest",
            isActive: isMemberActive || isAdminActive,
            icon: FaCar,
        },
        {
            displayText: "print tags",
            action: "printtag",
            isActive: isMemberActive || isAdminActive,
            icon: FaTags,
        },
        {
            displayText: "print",
            action: "print",
            isActive: isMemberActive || isAdminActive,
            icon: FaTags,
        },
        {
            displayText: "Users",
            action: "users",
            isActive: isTajneedActive,
            icon: FaUsers,
        },
        {
            displayText: "Department",
            action: "departments",
            isActive: isNazimActive,
            icon: FaRegBuilding,
        },
        // {
        //     displayText: "Tags",
        //     action: "departments",
        //     isActive: isNazimActive,
        //     icon: FaRegBuilding,
        // },
        {
            displayText: "Stats",
            action: "stats",
            isActive: isAdminActive,
            icon: FaRegBuilding,
        },
        {
            displayText:  (isMemberActive) ?  "Volunteer" : "Volunteers",
            action: "volunteers",
            isActive: false, //isMemberActive || isAdminActive || isNazimActive || isTajneedActive,
            icon: MdVolunteerActivism,
        },
    ]

    const changeActiveSideOption = (activeOption) => {
        navigate(`/${activeOption}`);
    }

    const logout = () => {
        dispatch(setLoggedInUserDetails({ userId: null, }));
        localStorage.removeItem('userDetails');
        localStorage.removeItem('userRoles');
        dispatch(setLoading(false));
        navigate('/');
    }
    return (
        <div className={`bg-theme-bgcolor h-[100vh] w-[100vw] flex flex-col`}>
        <div className="h-[100%] md:mx-0 md:my-auto min-w-[300px] w-full pr-5 flex">
            {/* Sidebar */}
            <div className="side-bar ">
                {/* Logo and Heading */}
                <div className="flex items-center h-[15%] p-5">
                    <img src={logo} alt="Logo" className="h-10 w-10 md:h-12 md:w-12 mr-3" />
                    <span className="text-2xl font-bold text-green-400">JALSA</span>
                </div>
    
                {/* Navigation Options */}
                <div className="flex-grow flex flex-col justify-between p-5 space-y-6">
                    <div className="space-y-4">
                        {navOptions.map((n, index) =>
                            n.isActive && (
                                <div
                                    key={index}
                                    className={`flex items-center p-3 rounded-md cursor-pointer ${activeSideOption === n.action ? 'bg-green-500' : 'hover:bg-green-600'}`}
                                    onClick={() => changeActiveSideOption(n.action)}
                                >
                                    <n.icon size={24} className="mr-3" />
                                    <span className="text-lg">{n.displayText.toUpperCase()}</span>
                                </div>
                            )
                        )}
                    </div>
    
                    {/* Logout */}
                    <div className="flex items-center cursor-pointer hover:text-red-500" onClick={logout}>
                        <FaArrowAltCircleLeft size={24} className="mr-3" />
                        <span className="text-lg">LOGOUT</span>
                    </div>
                </div>
            </div>
    
            {/* Main Content (Leave the same as your original) */}
            <div className="h-full w-[100%] md:w-[75%] block">
                <div className="top-heading-container h-[10%]">
                    <div className="flex flex-1 items-center md:hidden">
                        <img src={logo} className="h-[33px] w-[33px]" />
                        <span className="top-heading">JALSA</span>
                    </div>
                    <div className="name-container">
                        <span className="name">{`${user.userSurname.toUpperCase()} ${user.userFirstName.toUpperCase()}`}</span>
    
                        <FaUserCircle size={25} className="opacity-[49%]" onClick={() => setIsLogoutPopActive(!isLogoutPopActive)} />
                        <div className="pop-up-container">
                            <div onClick={logout} className={` ${isLogoutPopActive ? "pop-logout" : "hidden"}`}>
                                <span className="pop-up-text">LOGOUT</span>
                                <FaArrowAltCircleRight size={20} className="ml-2" />
                            </div>
                        </div>
                    </div>
                    <div className="breakline"></div>
                </div>
                <div className="h-[90%]">
                    {children}
                </div>
            </div>
        </div>
    
        {/* Bottom Navigation (Leave unchanged) */}
        <div className={`h-[8%] fixed bottom-0 z-[10] w-full bg-gray-800 text-white shadow-lg   md:hidden`}>
            <div className="bottom-option-mini-container">
                {navOptions.map((n, index) =>
                    n.isActive && (
                        <div
                            key={index}
                            className={`bottom-options ${activeSideOption === n.action ? 'active' : ''}`}
                            onClick={() => changeActiveSideOption(n.action)}
                        >
                            <div className={`icon ${activeSideOption === n.action ? 'bg-theme-color' : ''}`}>
                                <n.icon size={20} />
                            </div>
                            <span className="text">{n.displayText.toUpperCase()}</span>
                        </div>
                    )
                )}
            </div>
        </div>
    </div>
    

    );
}

export default NavigationLayout;