import { useSelector } from "react-redux";
import { SET_SETLOGGEDINUSER_DETAILS } from "../actions/user";
import user from "../reducers/user";


const setLoggedInUserDetailsFlow =
  ({ api, log }) =>

    ({ dispatch }) =>
      (next) =>
        (action) => {

          if (action.type === SET_SETLOGGEDINUSER_DETAILS) {
            api.userRequests.getMemberByMemberNo(action.payload.userId).then((response) => {
              action.payload.circuitId = response?.data?.circuitId;
              action.payload.surname = response?.data?.surname;
              action.payload.firstName = response?.data?.firstName;
              action.payload.jamaatId = response?.data?.jamaatId;
              action.payload.jamaatName = response?.data?.jamaatName;
              action.payload.circuitName = response?.data?.circuitName;
                if (action.payload.userRoles.some(r => r.toLowerCase().indexOf('circuit') > -1)) {
                  action.payload.userAuthLevel = 'circuit';
                }
                else if (action.payload.userRoles.some(r => r.toLowerCase().indexOf('jamaat') > -1)) {
                  action.payload.userAuthLevel = 'jamaat';
                }
                else if (action.payload.userRoles.some(r => r.toLowerCase().indexOf('national') > -1)) {
                  action.payload.userAuthLevel = 'national';
                }
                else if (action.payload.userRoles.some(r => r.toLowerCase().indexOf('admin') > -1)) {
                  action.payload.userAuthLevel = 'admin';
                }
                else if (action.payload.userRoles.some(r => r.toLowerCase().indexOf('nazim volunteer') > -1)) {
                  action.payload.userAuthLevel = 'nazim volunteer';
                }
                else {
                  action.payload.userAuthLevel = 'member';
                }
              localStorage.setItem("userDetails", JSON.stringify(action.payload));
              next(action);
            });
          }

          else {
            next(action);
          }
        };

export default [setLoggedInUserDetailsFlow];
