import React from "react";
import { useNavigate } from "react-router-dom";
import './Hero.css';
const Hero = ({ setIsModalActive }) => {
  const navigate = useNavigate();

  const openMemberModal = () => {
    setIsModalActive(true);
    navigate("/");
  };

  return (
    <section className="bg-green-600 h-[80vh] flex items-center">
      <div className="container mx-auto flex flex-col-reverse md:flex-row items-center justify-between px-4 md:px-0">
        {/* Left Side - Text Section */}
        <div className="md:w-1/2 flex flex-col items-center md:items-start text-center md:text-left">
          {/* Title with Fade-In and Color-Changing Animation */}
          <h1
            className="text-3xl sm:text-4xl md:text-5xl font-bold leading-snug mb-4 text-white"
          >
            {/* Join us in celebrating the Platinum Jubilee of Jalsa Salana Nigeria! */}
            Alhamdulillah, it's the Platinum Jubilee of Jalsa Salana Nigeria! 
          </h1>


          {/* Description with Fade-In Animation */}
          <p
            className="border-green-900 mb-6 opacity-0 animate-fadeInUp"
            style={{ animationDelay: "0.4s" }}
          >
            Join us for a spiritual journey of faith, unity, and inspiration as
            we come together to celebrate this annual gathering of the global
            Ahmadiyya Muslim Community.
          </p>

          {/* Location and Date with Fade-In Animation */}
          <div className="mb-6 opacity-0 animate-fadeInUp" style={{ animationDelay: "0.6s" }}>
            <p className="text-gray-700 text-base sm:text-lg font-semibold">
              <i className="fas fa-map-marker-alt text-green-500 mr-2"></i>
              Ilaro, Ogun State, Nigeria
            </p>
            <p className="text-gray-700 text-base sm:text-lg font-semibold">
              <i className="fas fa-calendar-alt text-green-500 mr-2"></i>
              Friday 20th - Sunday 22nd December, 2024
            </p>
          </div>

          {/* Buttons with Fade-In Animation */}
          <div
            className="flex justify-center md:justify-start space-x-4 opacity-0 animate-fadeInUp"
            style={{ animationDelay: "0.8s" }}
          >
            <button
              className="px-6 py-3 bg-white border-2 border-solid border-green-900 text-green-600 font-semibold rounded-md  text-2xl"
              onClick={() => openMemberModal()}
            >
              Register
            </button>
          </div>
        </div>

        {/* Right Side - Image Section with Rotation Animation */}
        <div className="md:w-1/2 flex justify-center md:justify-end mb-8 md:mb-0">
          <img
            src="/jas.png" 
            alt="Jalsa Logo"
            // className="h-fit"
            // className="w-48 sm:w-64 md:w-96 rounded-full animate-spinSlow"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
