import { COLUMNS } from "../../../application/utils/columns/MemberTagColumn";
import { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useRowSelect } from "react-table/dist/react-table.development";
import MainButton from "../buttons/MainButton";
import { CheckBox } from "../table/CheckBox";
import { FaPrint, FaDownload, FaUserTag } from 'react-icons/fa';
import '../table/Table.css';
import theme from "../../../application/utils/Theme";
import { useNavigate } from "react-router-dom";
import SearchInput from "../inputs/SearchInput";
import PaginateComponent from "../table/PaginateComponent";
import SmallButton from "../buttons/SmallButton";
import { TagComponent } from "./TagComponent";
import usePrintTag from "../../../application/hooks/usePrintTag";
import ReactToPdf from 'react-to-pdf';
import { useSelector } from "react-redux";
import { SpinnerDotted } from 'spinners-react';
import services from "../../../ioc/services";
import useAuthorization from "../../../application/hooks/useAuthorization";


const DisplayMembersTag = () => {
    useAuthorization();

    const navigate = useNavigate();
    //const userId = useSelector(state => state.user.userId);
    const user = useSelector(state => state.user);

    const userCircuitId = user.circuitId;
    const userJamaatId = user.jamaatId;
    const columns = useMemo(() => COLUMNS, []);
    const [tableData, setTableData] = useState([]);

    const [filter, setFilter] = useState();

    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        setPageSize,
        selectedFlatRows,
    } = useTable({
        columns,
        data: tableData
    },
        usePagination,
        useRowSelect,
        (hooks) => {

            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <CheckBox className="toggleCheck"  {...getToggleAllRowsSelectedProps()} />
                    ),
                    Cell: ({ row }) => <CheckBox className="toggleCheck"  {...row.getToggleRowSelectedProps()} />,
                },
                ...columns,
            ]);
        });

    const { pageIndex, pageSize } = state;


    const [activePageOption, setActivePageOption] = useState({ length: 1 });
    const [activePageIndex, setActivePageIndex] = useState(0);
    const [prevActivePage, setPrevActivePage] = useState(activePageIndex !== 0);
    const [nextActivePage, setNextActivePage] = useState(activePageIndex + 1 < activePageOption.length);
    const [isLoading, setIsLoading] = useState(false);
    const [bulkDetails, setBulkDetails] = useState([]);

    const goToNextPage = () => {
        setActivePageIndex(activePageIndex + 1);
    }


    const goToPreviousPage = () => {
        setActivePageIndex(activePageIndex - 1);
    }


    useEffect(() => {
        setIsLoading(true);
        if(user.userAuthLevel == "jamaat")
        {
            services.api.userRequests.getMemberParticipantsByJamaat(userJamaatId, activePageIndex + 1, pageSize).then(res => {
                setActivePageOption({ length: Math.round(res.data.totalCount / pageSize) !== 0 ? Math.round(res.data.totalCount / pageSize) : 1 });
                setTableData(res.data.rows);
                setIsLoading(false);
            })
        }
        else{
            services.api.userRequests.getMemberParticipantsByCircuit(userCircuitId, activePageIndex + 1, pageSize).then(res => {
                setActivePageOption({ length: Math.round(res.data.totalCount / pageSize) !== 0 ? Math.round(res.data.totalCount / pageSize) : 1 });
                setTableData(res.data.rows);
                setIsLoading(false);
            })
        }

    }, [userCircuitId, activePageIndex, pageSize]);

    useEffect(() => {
        setPrevActivePage(activePageIndex !== 0);
        setNextActivePage(activePageIndex + 1 < activePageOption.length);
    }, [activePageIndex, activePageOption]);

    const printTagsInBulk = () => {
        setBulkDetails(selectedFlatRows.map(s => s.original));
        setTimeout(()=>{setCurrentId(`bulk`);}, 1000);
    }

    const viewTag = (registrationId) => {
        navigate(`${registrationId}`);
    }

    const [currentId, setCurrentId] = usePrintTag();

    return (
        <div className="w-full h-[80%] md:h-full   items-center md:w-[95%] ">
            <div className="items-center flex">

                <div className="flex-1">
                    {
                        selectedFlatRows.length > 1 &&
                        <MainButton color="#16A34A" background="white" onClick={() => { printTagsInBulk(); }}>PRINT BULK ({selectedFlatRows.length})</MainButton>
                    }
                </div>
                <div className="flex justify-end ">
                    <SearchInput filter={filter} setFilter={setFilter} />
                </div>
            </div>
            {
                bulkDetails.length > 0 &&
                <>
                    <div className="hidden bg-white" >
                        <div id="bulk">
                            {
                                bulkDetails.map(t =>
                                    <>
                                        <TagComponent details={t} />
                                        <div className="my-[140px]"></div>
                                    </>)
                            }
                        </div>

                    </div>
                </>
            }
            <div className="md:px-5 h-[95%]  overflow-y-auto">
                {
                    tableData.length > 0 &&
                    <table  {...getTableProps()} className="table">
                        <thead className="thead">
                            {
                                headerGroups.map((headerGroup, index) => (
                                    <tr key={index} {...headerGroup.getHeaderGroupProps()} className="tr-head">

                                        {

                                            headerGroup.headers.map((column, index) => (
                                                <th key={index * 0.5} {...column.getHeaderProps()}>{column.render('Header')}</th>
                                            ))
                                        }
                                        <th>NAME</th>
                                        <th>ACTION</th>
                                    </tr>
                                ))
                            }

                        </thead>
                        <tbody {...getTableBodyProps()} className="tbody">
                            {
                                page.map((row, index) => {
                                    prepareRow(row)
                                    return (

                                        <>
                                            <div className="hidden bg-white">
                                                <TagComponent id={`print${index}`} details={row.original} />
                                            </div>
                                            <tr key={index} {...row.getRowProps()} className="tr-body">
                                                {
                                                    row.cells.map((cell, index) => {
                                                        return <td key={index} {...cell.getCellProps()}  >{cell.render('Cell')}</td>
                                                    })
                                                }
                                                <td>{`${row.original.lastName} ${row.original.firstName}`}</td>
                                               
                                                <td>
                                                    <div className="flex" >
                                                        <SmallButton onClick={() => viewTag(row.original.registrationNumber)} ><FaUserTag /></SmallButton>
                                                        <SmallButton onClick={() => setCurrentId(`print${index}`)} ><FaPrint /></SmallButton>
                                                    </div>

                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                }
                {
                    tableData.length === 0 && !isLoading &&
                    <div className="flex  h-full items-center justify-center">
                        <span className="opacity-[49%] tracking-[0.1em] font-bold  text-[12px] md:text-[14px]">NO MEMBERS REGISTERED YET!</span>
                    </div>
                }
                {
                    isLoading &&
                    <div className=" h-full w-full flex items-center justify-center">
                        <SpinnerDotted size={40} thickness={150} />
                    </div>
                }
            </div>
            <PaginateComponent pageIndex={activePageIndex} pageOptions={activePageOption} canNextPage={nextActivePage} canPreviousPage={prevActivePage} previousPage={goToPreviousPage} nextPage={goToNextPage} pageSize={pageSize} setPageSize={setPageSize} />
        </div >
    );
}

export default DisplayMembersTag;