import { useLocation } from "react-router-dom";
import useSelectAuthorization from "../../application/hooks/useSelectAuthorization";
import useAuthentication from "../../application/hooks/useAuthentication";
import AddEditUserComponent from "../components/user/AddEditUserComponent";
import AddRole from "../components/user/AddRole";
import ListUsersComponent from "../components/user/ListUsersComponent";
import NavigationLayout from "../layout/NavigationLayout";

const UsersContainer = () => {
    useAuthentication();
    useSelectAuthorization(['admin']);
    
    const location = useLocation();
    const activeOption = location.pathname.split("/")[2] ?? "all" ;
    return ( 
        <NavigationLayout>
        {
            (activeOption == 'add' || activeOption == 'edit') && <AddEditUserComponent/>
            
        }
        {
            activeOption == "all" && <ListUsersComponent/>
        }
        {
            activeOption == "addrole" && <AddRole/>
        }
        </NavigationLayout>
     );
}
 
export default UsersContainer;