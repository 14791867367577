export const COLUMNS = [

    {
        Header: "MEMBER NO",
        accessor: "chandaNo",
        Cell: ({ value }) => {
            return value;
        }
    },
    {
        Header: "JAMA'AT",
        accessor: "jamaatName",
        Cell: ({ value }) => {
            return value.toUpperCase();
        }
    },
   
]