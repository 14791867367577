import styled from "styled-components";
import theme from "../../../application/utils/Theme";
import { Field, ErrorMessage } from "formik";
import {InputLabel, TextError} from "./MainInput";
import InputLayout from "./InputLayout";

const InputField = styled.input`
        background-color: ${theme.backgroundColor};
        width:  322px;
        height: 56px;
        outline: none;
        padding: 0 0.5em;
        letter-spacing: 0.1em;

        @media (min-width: ${theme.breakPoint['tablet']}) {
            width: 500px;
            height: 70px;
        }

        &:focus{
            transition: all 1s;
            border-bottom: 1.5px solid ${theme.color};
        }
    `;

    const Label = styled(InputLabel)`
        opacity: 100%;
    `

const LoginInput = (props) => {

    const { label, name, type, ...rest } = props;
    
    return ( 
        <InputLayout label={label} name={name} >
            <Field name={name} >
                {({field})=> <InputField type={type? type : "text"} {...rest} {...field} />}
            </Field>
            <ErrorMessage name={name} component={TextError}/>
        </InputLayout>
     );
}
 
export default LoginInput;
export {InputField};