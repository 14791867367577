import React, { useState } from "react";
import Navbar from "../components/landing/Navbar";
import Hero from "../components/landing/Hero";
import LoginModal from "../components/landing/LoginModal";
import Countdown from "../components/landing/Countdown";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const LoginPage = () => {
  const navigate = useNavigate();
  const userId = JSON.parse(localStorage.getItem("userDetails"))?.userId;
  const user = useSelector((state) => state.user);
  const userIdStore = user.userId;
  const userAuthLevel = user.userAuthLevel;
const loc = window.location.href.split("=");
console.log(loc, "location");

  useEffect(() => {
    if (userId !== undefined && userIdStore !== null) {
      if (
        userAuthLevel == "national" ||
        userAuthLevel == "circuit" ||
        userAuthLevel == "jamaat"
      )
        navigate("/dashboard");
      else if (userAuthLevel == "nazim volunteer") navigate("/departments");
      else navigate("/register");
    }
  }, [userId, userIdStore]);
  const [isModalActive, setIsModalActive] = useState(false);

  return (
    <>
      <Navbar setIsModalActive={setIsModalActive}/>
      <Hero setIsModalActive={setIsModalActive} />
      {isModalActive && <LoginModal setIsModalActive={setIsModalActive} />}
      <Countdown/>
    </>
  );
};

export default LoginPage;
