import styled from "styled-components";
import theme from "../../../application/utils/Theme";
import { InputLabel } from "../inputs/MainInput";

const DisplayText = styled.p`
    color: black;
    font-size: 15px;
    letter-spacing: 0.15em;
    outline: none;
    margin-top: 4px;
    font-weight: 600;
    @media (min-width: ${theme.breakPoint['tablet']}) {
        margin-top: 6px;
        font-size: 20px;
    }
    
`

const DisplayDetails= styled.div`
    display: flex;
    flex-direction: column;
`

export  {DisplayDetails, DisplayText};