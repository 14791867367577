import { actions } from 'react-table/dist/react-table.development';
import * as userActions from '../actions/user'


const initialState = {
    userId: JSON.parse(localStorage.getItem('userDetails'))?.userId ?? null,
    userFirstName: JSON.parse(localStorage.getItem('userDetails'))?.firstName ?? '',
    userSurname: JSON.parse(localStorage.getItem('userDetails'))?.surname ?? '',
    userRoles: JSON.parse(localStorage.getItem('userDetails'))?.userRoles ?? [],
    authToken: JSON.parse(localStorage.getItem('userDetails'))?.authToken ?? null,
    circuitId: JSON.parse(localStorage.getItem('userDetails'))?.circuitId ?? null,
    jamaatId: JSON.parse(localStorage.getItem('userDetails'))?.jamaatId ?? null,
    circuitName: JSON.parse(localStorage.getItem('userDetails'))?.circuitName ?? null,
    jamaatName: JSON.parse(localStorage.getItem('userDetails'))?.jamaatName ?? null,
    userAuthLevel: JSON.parse(localStorage.getItem('userDetails'))?.userAuthLevel ?? null,
    
}

export default  (state = initialState, action) => {
    switch (action.type) {
        case (userActions.SET_USER_ROLE):
            return { ...state, userRole: action.payload };
        case (userActions.SET_SETLOGGEDINUSER_DETAILS):
            return {
                ...state,
                userRoles: action.payload.userRoles,
                userId: action.payload.userId,
                userFirstName: action.payload.firstName,
                userSurname: action.payload.surname,
                authToken: action.payload.authToken,
                circuitId: action.payload.circuitId,
                jamaatId: action.payload.jamaatId,
                circuitName: action.payload.circuitName,
                jamaatName: action.payload.jamaatName,
                userAuthLevel: action.payload.userAuthLevel,
            };
        default:
            return state;
    }
}