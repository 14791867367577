import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import services from "../../../ioc/services";


const DeleteGuestModal = ({ fullName, setModal, data, setData }) => {
    const [guestMembers, setGuestMembers] = useState([]);
    const navigate = useNavigate();
    const params = useParams();
    let regNumber = params?.jalsaNo;

    const minimizeModal = (e) => {
        if (e.target.classList.contains("modal")) {
            setModal(false);
            navigate("/register/guests");
        }
    }


    const removeGuest = () => {
        services.api.userRequests.deleteParticipantByGuestJalsaNo(regNumber).then(res => {
            if (res?.data?.succeeded) {
                const newData = data.filter(d=> d?.registrationNumber != regNumber);
                setData(newData);
                services.toast.success(res?.data?.messages[0]);
            }
            else {
                services.toast.error(res?.data?.messages[0]);
            }
            navigate("/register/guests");
        })
        
        setModal(false);
    }

    return (
        <div className="container flex justify-center mx-auto">
            <div className="absolute modalContainer inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 modal" onClick={(e) => minimizeModal(e)}>
                <div className="max-w-sm p-6 bg-white divide-gray-500 ">
                    <div className="flex items-center justify-between">
                        <p>Are you sure you want to delete Guest: 
                            <strong> {fullName}</strong>
                        </p>
                    </div>

                    <div className="text-center md:text-right mt-4 md:flex md:justify-end">
                        <button onClick={() => removeGuest()} className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-red-200 text-red-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2">Delete
                        </button>
                        <button onClick={(e) => minimizeModal(e)} className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4
        md:mt-0 md:order-1 modal">Cancel</button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default DeleteGuestModal;