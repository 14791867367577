import React, { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useRowSelect } from "react-table/dist/react-table.development";
import { COLUMNS } from "../../../application/utils/columns/ManifestMemberColumn";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaPlus, FaUserPlus, FaMinusCircle, FaEdit } from 'react-icons/fa';
import MainButton from "../buttons/MainButton";
import SmallButton from "../buttons/SmallButton";
import AddMembersModal from "../modals/PrintMultipleMembers";
import NavigationLayout from "../../layout/NavigationLayout";
import '../table/Table.css';
import { TagComponent } from "../../components/printag/TagComponent";
import { CheckBox } from "../table/CheckBox";
import '../manifest/ManifestComponent.css';
import services from "../../../ioc/services";
import { useDispatch, useSelector } from "react-redux";
import usePrintTag from "../../../application/hooks/usePrintTag";
import { SpinnerDotted } from 'spinners-react';
import { setLoading } from '../../../application/store/actions/ui';

const AddEditManifestComponent = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const activeState = location.pathname.split("/")[2] ?? "all";
    const isLoading = useSelector(state => state.ui.loading);
    const [editManifestData, setEditManifestData] = useState();
    const [manifestMembers, setManifestMembers] = useState([]);
    const [addedParticipantRegNo, setAddedParticipantRegNo] = useState("");
    const [isModalActive, setIsModalActive] = useState(false);
    const [errorMessage, setErroMessage] = useState("");
    const [newParticipant, setNewParticipant] = useState([]);
    const [currentId, setCurrentId] = usePrintTag();
    const [bulkDetails, setBulkDetails] = useState([]);
  
    const addMemberToManifest = () => {
        if (addedParticipantRegNo.length > 0) {
            dispatch(setLoading(true));
            services.api.userRequests.getParticipantByRegNo(addedParticipantRegNo).then(res => {
                const isMemberExist = manifestMembers.some(m => m?.registrationNumber?.toLowerCase() === addedParticipantRegNo.toLowerCase());
                if (typeof (res) == 'object' && !isMemberExist) {
                    if(res.status === false)
                    {
                              setErroMessage(`Participant With Reg No:(${addedParticipantRegNo}) Doesn't Exist!`)
                              return;
                    }
                    activeState !== "add" ? setNewParticipant([...newParticipant, res.registrationNumber]) : console.log("");
                    setManifestMembers([...manifestMembers, res]);
                    setIsModalActive(false);
                    setAddedParticipantRegNo("");
                }
                else {
                    isMemberExist ? setErroMessage("Participant has already been added!")  : setErroMessage(`Participant With Reg No:(${addedParticipantRegNo}) Doesn't Exist!`) ;
                    
                }
                dispatch(setLoading(false));
            })

        }
    }


    const columns = useMemo(() => COLUMNS, []);
    const data = manifestMembers;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        selectedFlatRows,
        prepareRow,
    } = useTable({
        columns,
        data
    },
    usePagination,
    useRowSelect,
    (hooks) => {

            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <CheckBox className="toggleCheck"  {...getToggleAllRowsSelectedProps()} />
                    ),
                    Cell: ({ row }) => <CheckBox className="toggleCheck"  {...row.getToggleRowSelectedProps()} />,
                },
                ...columns,
            ]);
        });


    useEffect(() => {
        if (activeState === "edit") {
            let reference = params.referenceNumber.toLowerCase();
            services.api.userRequests.getManifestByReferenceNo(reference).then(res => {
                if (res.data !== null) setEditManifestData(res.data);
                else {
                    services.toast.error(res.messages[0]);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (editManifestData !== undefined && manifestMembers.length == 0) {
           
            let manifetOcuupants = [];
            editManifestData?.manifestOccupants.forEach(m => {
                services.api.userRequests.getParticipantByRegNo(m.jalsaRegNo).then(res => {
                    manifetOcuupants.push(res);
                    setManifestMembers([...manifestMembers, ...manifetOcuupants]);
                }
                )
            })
        }
    }, [editManifestData]);


    const printTagsInBulk = () => {
        setBulkDetails(selectedFlatRows.map(s => s.original));
        setTimeout(()=>{setCurrentId(`bulk`);}, 1000);
    }



    return (
        <NavigationLayout>
        <div className="add-manifest-container ">
            {
                isModalActive &&
                <AddMembersModal setIsModalActive={setIsModalActive} userInput={addedParticipantRegNo} setUserInput={setAddedParticipantRegNo} addMemberToManifest={addMemberToManifest} errorMessage={errorMessage} setErrorMessage={setErroMessage} isLoading={isLoading} />
            }
            <div className="add-manifest-minicon">
                
               
                <div className="add-manifest-members">
                    <div className="flex  justify-end h-[37px]">
                        <SmallButton className="flex items-center " onClick={() => setIsModalActive(true)}>
                            <FaUserPlus className="mr-[10px]" />Add Participants
                        </SmallButton>
                    </div>
                    <div className="w-full h-[80%] md:h-full   items-center md:w-[95%] ">
            <div className="items-center flex">

                <div className="flex-1">
                    {
                        selectedFlatRows.length > 0 &&
                        <MainButton color="#16A34A" background="white" onClick={() => { printTagsInBulk(); }}>PRINT BULK ({selectedFlatRows.length})</MainButton>
                    }
                </div>
               
            </div>
            {
                bulkDetails.length > 0 &&
                <>
                    <div className="hidden bg-white" >
                        <div id="bulk">
                            {
                                bulkDetails.map(t =>
                                    <>
                                        <TagComponent details={t} />
                                        <div className="my-[140px]"></div>
                                    </>)
                            }
                        </div>

                    </div>
                </>
            }
            <div className="md:px-15 h-[170%]  overflow-y-auto">
                {
                    manifestMembers.length > 0 &&
                    <table  {...getTableProps()} className="table">
                        <thead className="thead">
                            {
                                headerGroups.map((headerGroup, index) => (
                                    <tr key={index} {...headerGroup.getHeaderGroupProps()} className="tr-head">

                                        {

                                            headerGroup.headers.map((column, index) => (
                                                <th key={index * 0.5} {...column.getHeaderProps()}>{column.render('Header')}</th>
                                            ))
                                        }
                                        <th>NAME</th>
                                    </tr>
                                ))
                            }

                        </thead>
                        <tbody {...getTableBodyProps()} className="tbody">
                            {
                                page.map((row, index) => {
                                    prepareRow(row)
                                    return (

                                        <>
                                            <div className="hidden bg-white">
                                                <TagComponent id={`print${index}`} details={row.original} />
                                            </div>
                                            <tr key={index} {...row.getRowProps()} className="tr-body">
                                                {
                                                    row.cells.map((cell, index) => {
                                                        return <td key={index} {...cell.getCellProps()}  >{cell.render('Cell')}</td>
                                                    })
                                                }
                                                <td>{`${row.original.lastName} ${row.original.firstName}`}</td>
                                            </tr>
                                        </>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                }
                {
                    manifestMembers.length === 0 && !isLoading &&
                    <div className="flex  h-full items-center justify-center">
                        <span className="opacity-[49%] tracking-[0.1em] font-bold  text-[12px] md:text-[14px]">NO MEMBERS ADDED YET!</span>
                    </div>
                }
                {
                    isLoading &&
                    <div className=" h-full w-full flex items-center justify-center">
                        <SpinnerDotted size={40} thickness={150} />
                    </div>
                }
            </div>
           
            </div >

                </div>
            </div>
        </div>
        </NavigationLayout>
    );
}

export default AddEditManifestComponent;