import React from "react";
import RegistrationContainer from "../container/RegistrationContainer";
import PrintTagContainer from "../container/PrintTagContainer";
import ManifestControlComponent from "../components/manifest/ManifestControlComponent";
import { HashRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./LoginPage";
import ScanPage from "./ScanPage";
import Dashboard from "../components/dashboard/Dashboard";
import CreateEvent from "../components/event/CreateEvent";
import PageNotFound from "../components/pageStatus/PageNotFound";
import UsersContainer from "../container/UsersContainer";
import VolunteerContainer from "../container/VolunteerContainer";
import DepartmentContainer from "../container/DepartmentContainer";
import CircuitStatsContainer from "../container/CircuitStatsContainer";
import PrintComponent from "../components/print/PrintComponent";


const Router = () => {
  const loginParams = ["/", "/login", "/login/admin", "/login/tajneed"]
  const scanParams = ["", "/scan"]
  const registerParams = ["", "cancel/:jalsaNo", "circuit", "jamaat", "guest", "volunteer", "guests", "deleteguest/:jalsaNo"];
  const manifestParams = ["", "add", "reference", "edit/:referenceNumber", "details/:referenceNumber", "participants/:referenceNumber", "print/:referenceNumber"];
  const printtagParams = ["", "member", "member/:registrationId", "guest", "guest/:registrationId"];
  const printParams = ["", ];
  const userParams = ["", "add", "edit/:username", "addrole"];
  const departmentParams = ["", "add", "delete/:departmentId", "edit/:departmentId"];
  const statParams = ["", "add"];
  const volunteersParams = ["", "add", "add/:jalsano", "edit/:jalsano", "cancel/:jalsano"];

  return (
    <HashRouter>
      <Routes>
        {
          loginParams.map(r => <Route key={Math.random(100)} path={r} element={<LoginPage />} ></Route>)
        }
        {
          scanParams.map(r => <Route key={Math.random(100)} path={r} element={<ScanPage />} ></Route>)
        }
        {
          <Route path={"/dashboard"} element={<Dashboard />} ></Route>
        }
        {
          registerParams.map(r => <Route key={Math.random(100)} path={`register/${r}`} element={
            <RegistrationContainer />} />)
        }
        
        {
          manifestParams.map(r => <Route key={Math.random(100)} path={`manifest/${r}`} element={
            <ManifestControlComponent />} />)
        }
        {
          printtagParams.map(r => <Route key={Math.random(100)} path={`printtag/${r}`} element={
            <PrintTagContainer />} />)
        } 
        {
          printParams.map(r => <Route key={Math.random(100)} path={`print`} element={
            <PrintComponent />} />)
        } 
        
        {
          userParams.map(r => <Route key={Math.random(100)} path={`users/${r}`} element={
            <UsersContainer />} />)
        }
        {
          volunteersParams.map(r => <Route key={Math.random(100)} path={`volunteers/${r}`} element={
            <VolunteerContainer />
          } />)
        }
        {
          departmentParams.map(r => <Route key={Math.random(100)} path={`departments/${r}`} element={
            <DepartmentContainer />
          } />)
        }
        {
          departmentParams.map(r => <Route key={Math.random(100)} path={`stats/${r}`} element={
            <CircuitStatsContainer />
          } />)
        }
        {
          <Route path="/event" element={<CreateEvent />} />
        }
        <Route path="*" element={<PageNotFound />} />
      </Routes>

    </HashRouter >


  );
}

export default Router;