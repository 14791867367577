import { useLocation, useNavigate, useParams } from "react-router-dom";
import TopNavigationComponent from "../components/topnav/TopNavigationComponent";
import DisplayMembersTag from "../components/printag/DisplayMembersTags";
import DisplayGuestsTag from "../components/printag/DisplayGuestTags";
import ViewTagComponent from "../components/printag/ViewTagComponent";
import { useSelector } from "react-redux";
import ViewSelfTag from "../components/printag/ViewSelfTagComponent";
import NavigationLayout from "../layout/NavigationLayout";
import useAuthentication from "../../application/hooks/useAuthentication";

const PrintTagContainer = () => {

    useAuthentication();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const userAuthLevel = useSelector(state => state.user.userAuthLevel);

    const activeOption = location.pathname.split("/")[2] ?? "self";

    const navigateOptions = (activeOpt) => {
        if (activeOpt == "self") {
            navigate(`/printtag`);
        }
        else {

            navigate(`/printtag/${activeOpt}`);
        }
    }

    const navOptions = [
        {
            state: "self",
            displayText: "personal tag"
        },
        {
            state: "guest",
            displayText: "guest tag"
        },
    ]

    if (userAuthLevel == "jamaat" || userAuthLevel == "circuit" || userAuthLevel == "national") {
        navOptions.splice(1, 0, {
            state: "member",
            displayText: "member tag"
        });
    }

    return (
        <NavigationLayout>

            <TopNavigationComponent navOptions={navOptions} activeOption={activeOption} setActiveOption={navigateOptions} />
            <div className="w-full h-full py-5 md:h-[90%] overflow-y-auto">
                {
                    activeOption == "self" && <ViewSelfTag/>
                }
                {
                    (activeOption == "member" && params.registrationId === undefined) &&
                    <DisplayMembersTag />
                }
                {
                    (activeOption == "member" && params.registrationId !== undefined) &&
                    <ViewTagComponent/>
                }
                {
                    (activeOption == "guest" && params.registrationId === undefined) &&
                    <DisplayGuestsTag />
                }
                {
                    (activeOption == "guest" && params.registrationId !== undefined) &&
                    <ViewTagComponent />
                }
            </div>
        </NavigationLayout>
    );
}

export default PrintTagContainer;