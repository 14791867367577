import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllCircuits, useGetAllEvents, useGetAllJamaats } from "../../../application/hooks/queryHooks";
import { setLoading } from "../../../application/store/actions/ui";
import LabelledInputField from "../inputs/LabelledInputField";
import NavigationLayout from "../../layout/NavigationLayout";
import CardStatsContainer from "../../container/CardStatsContainer"
import useAuthentication from "../../../application/hooks/useAuthentication";
import SmallButton from "../buttons/SmallButton";
import { useNavigate } from "react-router-dom";
import useAuthorization from "../../../application/hooks/useAuthorization";
import DisplayParticipants from "../participant/DisplayParticipants";

const AdminDashboard = () => {
    useAuthentication();
    useAuthorization();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    const userCircuitId = user.circuitId;
    const userJamaatId = user.jamaatId;
    const userAuthLevel = user.userAuthLevel;
    const userFirstName = user.userFirstName;
    const userSurname = user.userSurname;

    const [isViewParticipant, setIsViewParticipant] = useState(false);
    const [participantData, setParticipantData] = useState({title:'', data: []});

    const [selectedJamaat, setSelectedJamaat] = useState(userJamaatId);
    const [selectedCircuit, setSelectedCircuit] = useState(userCircuitId);
    const [selectedEventYear, setSelectedEventYear] = useState(new Date().getFullYear());

    const [jamaats, setJamaats] = useState([]);
    const [circuits, setCircuits] = useState([]);
    const [events, setEvents] = useState([]);


    const { data: circuitData, isLoading: isCircuitLoading } = useGetAllCircuits();
    const { data: jamaatData, isLoading: isJamaatLoading } = useGetAllJamaats();
    const { data: eventData, isLoading: isEventLoading } = useGetAllEvents(2022);

    useEffect(() => {
        if (circuitData?.data !== undefined) {
            setCircuits(circuitData.data);
        }
        if (jamaatData?.data !== undefined) {
            setJamaats(jamaatData?.data);
        }
        if (eventData !== undefined) {
            setEvents(eventData);
        }
    }, [isCircuitLoading, isJamaatLoading, isEventLoading])

    const handleCircuitChange = (value) => {
        setSelectedCircuit(value);
        setSelectedJamaat(jamaats.filter(j => j.circuitId == value)[0].jamaatId);
    }

    const handleJamaatChange = (value) => {
        setSelectedJamaat(value);
    }

    const handleEventYearChange = (value) => {
        setSelectedEventYear(value);
    }

    return (
        <NavigationLayout>
            {
                isViewParticipant && <DisplayParticipants setIsViewParticipant={setIsViewParticipant} participantData={participantData} />
            }
            {
                !isViewParticipant &&
                <div className="p-4 h-full overflow-y-scroll">
                    <div className=" top-part flex justify-between items-center">
                        <div className="hidden md:block">
                            <h1 className="font-bold text-2xl capitalize">Welcome {userSurname.toLowerCase()} {userFirstName.toLowerCase()}</h1>
                        </div>
                        <div className="flex gap-2">
                            <LabelledInputField value={selectedCircuit} type="select" label="Circuit" id="circuit"
                                onChange={(e) => handleCircuitChange(e.target.value)}
                                disabled={userAuthLevel !== "national" && userAuthLevel !== 'admin'}
                                data={circuits.map(circuit => (
                                    {
                                        value: circuit.circuitId,
                                        text: circuit.circuitName
                                    }
                                ))} />
                            <LabelledInputField type="select" label="Jamaat" id="jamaat" value={selectedJamaat}
                                onChange={(e) => handleJamaatChange(e.target.value)}
                                disabled={userAuthLevel !== "national" && userAuthLevel !== "circuit" && userAuthLevel !== 'admin'}
                                data={jamaats
                                    .filter(j => j.circuitId == selectedCircuit)
                                    .map(jamaat => (
                                        {
                                            value: jamaat.jamaatId,
                                            text: jamaat.jamaatName
                                        }
                                    ))} />
                            <LabelledInputField type="select" label="Event" id="event" value={selectedEventYear}
                                onChange={(e) => handleEventYearChange(e.target.value)}
                                data={events
                                    .map(event => (
                                        {
                                            value: parseInt(new Date(event.eventYear).getFullYear()),
                                            text: new Date(event.eventYear).getFullYear()
                                        }
                                    ))} />

                        </div>
                    </div>

                    <div>
                        <CardStatsContainer selectedCircuit={selectedCircuit} selectedJamaat={selectedJamaat} selectedYear={selectedEventYear} setIsViewParticipant={setIsViewParticipant} setParticipantData={setParticipantData} />
                    </div>

                </div>
            }


        </NavigationLayout>
    )

}

export default AdminDashboard;

