import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import MainButton from "../buttons/MainButton";
import ListVolunteer from './ListVolunteers';
import services from "../../../ioc/services";
const axios = require('axios');

const ExportCSV = () => {
    const fileName = "VOLUNTEERSLIST";
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(false);

    const headers = [
        { label: "JalsaNo", key: "jalsaNo" },
        { label: "LastName", key: "lastName" },
        { label: "FirstName", key: "firstName" },
        { label: "AuxilliaryBody", key: "auxilliaryBody" },
        { label: "CircuitName", key: "circuitName" },
        { label: "JammatName", key: "jammatName" },
        { label: "DepartmentName", key: "departmentName" },
        { label: "PhoneNumber", key: "phoneNumber" }
    ];
   
    useEffect(() => {
        services.api.userRequests.getVolunteers().then(res => {
            setUserData(res);
            setLoading(false);
        });
    }, []);

    return (
        <div className='container'>
            <MainButton
                variant="contained"
                color="primary"
                className='export-btn'
            >
                <CSVLink
                    headers={headers}
                    data={userData}
                    filename={fileName}
                    style={{ "textDecoration": "none", "color": "#fff" }}
                >
                    {loading ? 'Loading csv...' : 'EXPORT DATA'}
                </CSVLink>
            </MainButton>
        </div>
    );
}

export default ExportCSV;