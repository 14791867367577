export const COLUMNS = [

    {
        Header: "REGISTRATION NO",
        accessor: "membershipNumber",
        Cell:({value})=>{
            return value?.toUpperCase()??"";
        }
      
    },
    {
        Header: "PARTICIPANT TYPE",
        accessor: "participantType",
        Cell: ({ value }) => {
            return value?.toUpperCase()??"";
        }
    }
]