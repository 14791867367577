import { useLocation } from "react-router-dom";
import useSelectAuthorization from "../../application/hooks/useSelectAuthorization";
import useAuthentication from "../../application/hooks/useAuthentication";
import ListCircuitStats from "../components/circuitStats/ListCircuitStats";
import AddEditDepartment from "../components/department/AddEditDepartment";
import NavigationLayout from "../layout/NavigationLayout";

const CircuitStatsContainer = () => {
    console.log("eeeeeeeeennnnnnnnrrrrrrrrrrrrrrrrrrr")
    const location = useLocation();
    const activeOption = location.pathname.split("/")[2] ?? "all" ;
    return ( 
        <NavigationLayout>
        {
            (activeOption == "all" ) && <ListCircuitStats/>
        }
        </NavigationLayout>
     );
}
 
export default CircuitStatsContainer;